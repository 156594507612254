import axios from 'axios'

async function request(path, method, data={}, parse_result=null, authless=false, from_reauth=false) {
    try {
        const request_options = build_request_options(path, method, data, authless)

        const res = await axios.request(request_options);

        if (res.status === 200) {
            if (parse_result && res.data.success) {
                try {
                    return {success: true, parsed_result: parse_result(res)};
                } catch {
                    return {success: false, error: 'Failure parsing result, check the parse_result param'};
                }
            } else {
                return {success: true, parsed_result: null};
            }
        } else if (res.status === 401 && !from_reauth && !authless) {
            await reauthenticate();
            return await request(path, method, data, parse_result, authless, true);
        }
        // catch the rest as error
        const error = JSON.parse(res.data);
        return {success: false, error: error.message};
    } catch (error) {
        if (error.request.status === 401 && !from_reauth && !authless) {
            await reauthenticate();
            return await request(path, method, data, parse_result, authless, true);
        } else {
            return {success: false, error: error?.response?.data?.message ? error.response.data.message : error.message};
        }
    }
}

async function reauthenticate() {
    const reauth_res = await axios.get(process.env.REACT_APP_API_BASE_URL + '/api/user/refreshToken', {headers: {'refreshToken': localStorage.getItem('refreshToken')}});
    localStorage.setItem('refreshToken', reauth_res.data.user.refreshToken);
    localStorage.setItem('accessToken', reauth_res.data.user.accessToken);
}

function build_request_options(path, method, data, authless) {
    const headers = {}
    if (!authless) headers['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');
    if (method !== "GET") headers['Content-Type'] = 'application/json';
    const request_options = {
        url: process.env.REACT_APP_API_BASE_URL + '/api' + path,
        method: method,
        headers: headers
    };
    if (method !== "GET") request_options['data'] = data;
    return request_options;
}

/**
 * 
 * @param {*} path path after /api
 * @param {*} data json object to include in body
 * @param {*} parse_result function to parse the json response
 * @returns either success == true and the parsed_result, or success == false and an error message
 */
async function post(path, data, parse_result) {
    const authless = ['/user/signIn', '/user/guardian'].includes(path);
    return request(path, "POST", data, parse_result, authless);
}

/**
 * 
 * @param {*} path path after /api
 * @param {*} data json object to include in body
 * @param {*} parse_result function to parse the json response
 * @returns either success == true and the parsed_result, or success == false and an error message
 */
 async function patch(path, data, parse_result) {
    return request(path, "PATCH", data, parse_result);
}

/**
 * 
 * @param {*} path path after /api
 * @param {*} parse_result function to parse the json response
 * @returns either success == true and the parsed_result, or success == false and an error message
 */
async function get(path, parse_result) {
    return request(path, "GET", {}, parse_result);
}

const pmp_axios = {'post': post, 'get': get, 'patch': patch};

export default pmp_axios