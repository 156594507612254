import React from 'react';
import { Button, makeStyles, Typography, Grid, useTheme } from '@material-ui/core';

const useLightStyle = makeStyles( theme => ({
  root: {
    background: theme.palette.primary.main,
    variant: 'contained',
    '&:hover': { background: theme.palette.primary.main },
    borderRadius: '5px',
    margin: 'auto',
  },
}));

const useLightWithBorderStyle = makeStyles( theme => ({
  root: {
    background: theme.palette.primary.main,
    variant: 'contained',
    '&:hover': { background: theme.palette.primary.main },
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '20px',
    margin: 'auto',
  },
}));

const useDarkStyle = makeStyles( theme => ({
  root: {
    background: theme.palette.secondary.main,
    variant: 'contained',
    '&:hover': { background: theme.palette.secondary.main },
    borderRadius: '5px',
    border: `2px solid ${theme.palette.secondary.main}`,
    margin: 'auto',
  },
  disabled: {
    background: '#7a7a7a',
    variant: 'contained',
    '&:hover': { background: '#7a7a7a' },
    borderRadius: '5px',
    border: `2px solid #7a7a7a`,
    margin: 'auto',
  }
}));

const useInvertedStyle = makeStyles( theme => ({
  root: {
    background: theme.palette.secondary.contrastTextDark,
    variant: 'contained',
    '&:hover': { background: theme.palette.secondary.contrastTextDark },
    borderRadius: '5px',
    border: `2px solid ${theme.palette.secondary.contrastTextDark}`,
    margin: 'auto'
  }
}));

function StyledButton(props) {
  const light = useLightStyle();
  const lightBorder = useLightWithBorderStyle();
  const dark = useDarkStyle();
  const inverted = useInvertedStyle();
  const theme = useTheme();

  if (props.fullWidth) {
    props.style.width = '100%'
  };

  const style = {height: '100%', ...props.style};
  let classes;
  let text;

  switch (props.variant) {
    default: case 'dark':
      classes = dark;
      text = theme.palette.primary.main;
      break;
    case 'light':
      classes = light;
      text = theme.palette.secondary.main;
      break;
    case 'light-border':
      classes = lightBorder;
      text = theme.palette.secondary.main;
      break;
    case 'inverted':
      classes = inverted;
      text = theme.palette.primary.contrastTextDark;
      break;
  }

  // Don't pass the incoming 'variant' property into the child button
  const forwardProps = {...props}
  delete forwardProps.variant;

  return <Grid container style={style}>
    <Button {...forwardProps} className={props.disabled ? classes.disabled : classes.root}>
      <Typography variant='body2' style={{color: text}}>{props.children}</Typography>
    </Button>
  </Grid>;
}

export default StyledButton;
