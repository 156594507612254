import React, { useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import EventsFilter from '../components/EventsFilter.js'
import Carousel from '../components/Carousel';
import Banner from '../components/Banner';

function Upcoming(props) {
    const default_filters = props.location?.state?.filters;

    const [events, setEvents] = useState([]);

    return (
        <Box>
            <Grid container item xs={12} justify='space-around' direction="row">
                <Grid item container xs={6} alignItems="center" justify="center" style={{minHeight: '35vh'}}> 
                    <EventsFilter onEventsChange={(events) => setEvents(events)} updateMode='partial' defaultFilters={default_filters}/>
                </Grid>
            </Grid>
            <Grid container direction="row" justify="space-around" alignItems="center">
                    <Carousel carouselTitle="Upcoming Clinics" data={events} slideType="event" />
            </Grid>
            <Grid container justify="space-around" alignItems="center" style={{padding: '1rem'}}>
                <Banner title="This is a title!" subtitle="This is a subtitle." />
            </Grid>
            <Grid style={{minHeight: '10vh'}} />
        </Box>
    );
}

export default Upcoming;
