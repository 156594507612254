import React, { useState, useEffect, useContext } from 'react';
import StripeCCForm from '../components/StripeCCForm'
import StyledButton from '../components/StyledButton';
import StyledModal from '../components/StyledModal';
import { Grid, Link, CircularProgress, makeStyles, Divider, MenuItem as SelectMenuItem } from '@material-ui/core';
// import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import StyledInput from './StyledInput';
import Trainer from './Trainer';
import AppContext from '../context';
// import Image from 'material-ui-image';
// import { FaCreditCard, FaRegCreditCard, FaPlus } from 'react-icons/fa'
import pmp_axios from '../utils/pmp_axios';
import Typography from '@material-ui/core/Typography';
import * as dayjs from 'dayjs';
import logo from '../assets/pmpLogo.png';
import check from '../assets/check.svg';

const months = [1,2,3,4,5,6,7,8,9,10,11,12];
const years = [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];
const fakeCard1 = {
    id: 1,
    cc: '************1032',
    lastFour: '1032',
    name: 'John Q Public',
    month: 2,
    year: 2023,
    address: '1369 W Ternhart St, West Chester NY 90021',
    cvv: '',
}

const fakeCard2 = {
    id: 2,
    cc: '************9947',
    lastFour: '0104',
    name: 'John Q Public',
    cvv:'333',
    month: 3,
    year: 2023
}

function PaymentModal(props) {
    const setOpen = props.setOpen;
    const setShowPayment = props.setShowPayment;
    const registration = props.state;
    const context = useContext(AppContext);
    const domain = context.domain;
    // suggest we add credit card IDs to the user's context so we can retrieve info about them.
    // not sure how that is stored.
    let invoiceId = registration?.invoiceId ? registration.invoiceId : null;
    let lastFour = registration?.stripeCCLastFour ? registration?.stripeCCLastFour : null;    
    const [ paymentIntentSecret, setPaymentIntentSecret ] = useState();
    const [ hasCardSaved, setHasCardSaved ] = useState(!!lastFour);
    const [ arePaymentFieldsComplete, setArePaymentFieldsComplete ] = useState(false);
    const [ savedCards, setSavedCards ] = useState(context.cards);
    const [ selectedCard, setSelectedCard ] = useState();
    const [ receiptUrl, setReceiptUrl ] = useState();
    const [ finalizeSuccess, setFinalizeSuccess ] = useState();
    const [ showConfirmation, setShowConfirmation ] = useState(false);
    const [ playerNames, setPlayerNames ] = useState();
    const [ageString, setAgeString] = useState();
    const [skillString, setSkillString] = useState();
    const [loading, setLoading] = useState(false);

    const cards = [fakeCard1, fakeCard2];
    const [paymentInfo, setPaymentInfo] = useState({
        month: '',
        year: '',
        cc: '',
        lastFour: '',
        name: '',
        cvv:'',
        month: 1,
        year: 2023,
        address: '',
    });
    const [errors, setErrors] = useState({
        month: null,
        year: null,
        cc: null,
        lastFour: null,
        name: null,
        cvv:null,
        month: null,
        year: null,
        address: null,
    });

    useEffect(() => {

        // build list of player names
        let playerName = ''
        if(registration?.players?.length > 1) {
            registration.players.forEach((player) => playerName += player.first + ', ');
            playerName = playerName.slice(0,playerName.length - 2);
        } else {
            playerName = registration?.players[0]?.first;
        }
        setPlayerNames(playerName);

        // turn list of ages into string
        if(registration?.event?.ages?.length) {
            let string = '';
            registration.event.ages.forEach((age) => {
                string += age.name + ', '
            })
            string = string.slice(0, string.length - 2);
            setAgeString(string.toUpperCase());
        }

        // turn list of skills into string
        if(registration?.event?.skills?.length) {
            let string = '';
            registration.event.skills.forEach((skill) => {
                string += skill.name + ', '
            })
            string = string.slice(0, string.length - 2);
            setSkillString(string.toUpperCase());
        }

        // temporary for dummy data - will overwrite once real data is all lined up
        setSavedCards(cards);
        setPaymentInfo(cards[0])
    },[registration])

    const useStyles = makeStyles({
        trainer: {
            minHeight: '2rem',
            border: '1px black solid',
            borderRadius: '10px',
            backgroundColor: 'rgba(0,0,0,0)',
            margin: 10, 
            padding: 10,
            display: 'inline-block',
        },
        trainerText: {
            paddingLeft: '0.4rem'
        },
        skillPill: {
            border: `1px solid #000`, 
            borderRadius: 5, 
            verticalAlign: 'middle',
            padding: '0.2rem',
            color: '#AEAEAE',
            fontSize: '0.75rem',
            fontFamily: 'axia',
        },
        // overwriting styles until pmp_theme.js is updated
        h3: {
            fontFamily: 'Neue Haas Grotesk Text Pro, sans-serif',
            fontSize: '1.5rem',
            letterSpacing: '0',
            fontWeight: 500,
        },
        h5: {
            fontFamily: 'Neue Haas Grotesk Text Pro, sans-serif',
            fontSize: '1.2rem',
            letterSpacing: '0',
        },
        body1: {
            fontFamily: 'Neue Haas Grotesk Text Pro, sans-serif',
            fontSize: '1rem',
            fontWeight: 500,
            letterSpacing: '0',
        },
        body2: {
            fontFamily: 'Neue Haas Grotesk Text Pro, sans-serif',
            color: '#AEAEAE',
            fontWeight: 400,
            fontSize: '0.85rem',
            paddingTop:'2%'
        },
        caption: {
            fontFamily: 'Neue Haas Grotesk Text Pro, sans-serif',
            fontSize: '0.6rem',
            fontWeight: 200,
            padding:0,
        },
        toggle: {
            backroundColor: 'rgba(0,0,0,0)',
            selected: {
                "&&": {
                  backgroundColor: 'black',
                  color: 'white',
                  borderColor: 'white'
                }
            },
            hover: {
                "&&": {
                    backroundColor: 'rgba(0,0,0,0)',
                    color: 'white',
                    borderColor: 'white'
                }
            }
        },
        input: {
            "& input": {
                padding: '10.5px 10px',
            },
            "& div div": { // this one is for dropdown selects
                padding: '10.5px 10px',
            },
        }
    });

    const classes = useStyles();

    /* begin API functions */
    // result is success
    const post_pay_with_saved_card = async () => {
        const invoiceObj = { invoiceId: invoiceId };
        return await pmp_axios.post('/payment/payWithCardOnFile', invoiceObj);
    };

    // result is payment intent secret needed to load Stripe payment form
    const post_create_payment_intent = async () => {
        const invoiceObj = { invoiceId: invoiceId };
        return await pmp_axios.post('/Payment/createPaymentIntent', invoiceObj, (res)=>res.data.data[0].paymentIntentSecret);
    };

    // result is success
    const post_finalize_payment = async () => {
        const invoiceObj = { invoiceId: invoiceId };
        return await pmp_axios.post('/payment/finalizePayment', invoiceObj, (res)=>res.data.data.receiptUrl);
    };
    /* end API functions */

    /* begin helper functions */
    const create_and_set_intent_secret = async () => {
        const intent_res = await post_create_payment_intent();
        if (intent_res.success) {
            setPaymentIntentSecret(intent_res.parsed_result);
        } else {
            // TODO handle error
        }
    }
    
    const setPaymentField = (field, value) => {
        if(errors[field]) setErrorField(field, false);
        if(value.length === 0) setErrorField(field, true);
        setPaymentInfo({...paymentInfo, [field]: value});
    }

    const setErrorField = (field, value) => {
        setArePaymentFieldsComplete(false);
        setErrors({...errors, [field]: value});
    }

    const handleCancel = () => {
        setOpen(false);
        setShowPayment(false);
    }

    const handleCardSelect = (lastFour) => {

        // NOT DONE
        const cc = savedCards.find({lastFour: lastFour})
        setSelectedCard(cc);
        setPaymentInfo(...cc)
    }

    const checkFieldsAndPay = () => {
        setLoading(true);
        for (const key in paymentInfo) {
            if(paymentInfo[key].length < 1) {console.log(key); console.log(paymentInfo[key]); setErrorField(key,true);}
            else setErrorField(key,false);
        }

        if(paymentInfo?.cvv?.length!==3) setErrorField('cvv',true);

        const fieldsFilled = paymentInfo.name && paymentInfo.cc && paymentInfo.month && paymentInfo.year && paymentInfo.cvv?.length === 3 && paymentInfo.address
        if (fieldsFilled) pay_with_card_and_confirm();
        else {
            alert('Please fill in all payment fields.'); 
            console.log(errors)
            console.log(paymentInfo)
        }
    }
    /* end helper functions */

    /* begin event functions */
    const handle_payment_success = async () => {
        const finalize_res = await post_finalize_payment();
        if (finalize_res.success) {
            setFinalizeSuccess(true);
            setShowConfirmation(true);
            setReceiptUrl(finalize_res.parsed_result);
            
        } else {
            // TODO notify admin of error
            setFinalizeSuccess(true);
            setShowConfirmation(true);
            console.log('Error finalizing payment');
        }
    }

    const pay_with_card_and_confirm = async () => {
        setLoading(true);
        const pay_res = await post_pay_with_saved_card();
        if (pay_res.success) {
            const finalize_res = await post_finalize_payment();
            if (finalize_res.success) {                
                setFinalizeSuccess(true);
                setShowConfirmation(true);
                setReceiptUrl(finalize_res.parsed_result);
            } else {
                // TODO handle error
                alert('There was an issue with your card. Please check your info and try again.');
                setFinalizeSuccess(false);
            }
        } else {
            // TODO handle error
            alert('There was an issue with your card. Please check your info and try again.');
            setFinalizeSuccess(false);
        }
        setLoading(false);
    }
    /* begin event functions */

    /* begin render functions */
    function CollectPayment(props) {
        
        useEffect(() => {
            if (!paymentIntentSecret) {
                create_and_set_intent_secret();
            }
        }, []);
        
        if (paymentIntentSecret) {
            return (
                <Grid container alignItems="center"  style={{minHeight: '28rem'}}>
                    <Grid item xs={12}>
                        <Typography style={{marginBottom: '-2px'}} className={classes.h3} variant='h3' align="left">Payment</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider variant="fullWidth"/>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{minHeight: '3.5rem'}} />
                        <StripeCCForm secret={paymentIntentSecret} onSuccess={()=>handle_payment_success()}/>
                    </Grid>
                    <Grid item xs={12} style={{padding: 0}}>
                        <StyledButton style={{padding: 0, backgroundColor: 'rgba(0,0,0,0)', border: 'none', }} onClick={() => handleCancel()}><span style={{textDecoration: 'underline', color: '#EA3C3C', fontFamily: 'sans-serif', fontSize: '0.9rem', fontWeight: 500}}>Cancel</span></StyledButton>
                    </Grid>
                    <Grid item xs={12} spacing={0} style={{textAlign: 'center'}}>
                        <Typography className={classes.caption} variant='caption' align='center'>*Your security is important to us. We do not store your credit card information. Some disclaimer can go here about payment.*</Typography>
                    </Grid>
                    <Grid item xs={12} spacing={0} style={{textAlign: 'center'}}>
                        <div style={{minHeight: '5rem'}} />
                    </Grid>
                </Grid>
            );
        } else {
            return (                
                <PaymentLoading/>
            );
        }
    }

    function PaymentLoading(props) {
        return (
            <Grid container>
                <Grid item xs={12}>
                <Typography variant='body1' align="center">Payment Form Loading...</Typography>
                </Grid>
            </Grid>
        );
    }

    function RegistrationSummary({registration}) {

        return (
            <Grid container direction="row" alignItems="stretch" justify="space-between" style={{minHeight: '28rem'}}>
                <Grid item xs={12} >
                    <Typography className={classes.h3} variant='h3' align="left">Registration Summary</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider variant="fullWidth"/>
                </Grid>
                <Grid item xs={12} container direction="row">
                    <Grid item xs={9} >
                        <Typography className={classes.h5} variant='h5'>{registration.event.name}</Typography>
                    </Grid>
                    <Grid item xs={3} >
                        <Typography style={{textAlign: 'right'}} variant='body2'>${registration.invoiceAmount}.00</Typography>
                    </Grid>
                </Grid>
                {/* pills */}
                <Grid item xs={12} container direction="row">
                    <Grid item container spacing={1} style={{margin: 'auto'}} justify='left' direction="row">
                            {ageString?.length > 0 ?
                                <Grid item>
                                    <Typography style={{display: 'inline'}} className={classes.skillPill}>AGES:{ageString}</Typography>
                                </Grid>
                                : ""}
                            {skillString?.length > 0 ?
                                <Grid item>
                                    <Typography style={{display: 'inline'}} className={classes.skillPill}>SKILLS:{skillString}</Typography>
                                </Grid>
                                : ""}
                    </Grid>
                </Grid>
                {/* description */}
                <Grid item xs={12} container direction="row">
                    <Grid item xs={12} style={{ maxHeight: "40vh", overflow:"scroll"}}>
                        <Typography className={classes.body2} variant='body2' dangerouslySetInnerHTML={{ __html: registration.event.description}}></Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} container direction="row">
                    <Grid item xs={12} >
                        <Typography className={classes.body1} variant='body1'>Location: {registration.event.location.name}</Typography>
                        <Typography  className={classes.body1} >Start: {dayjs(registration.event.startDate).format('ddd, MMM D')}
                            {" - "}
                            {dayjs(registration.event.endDate).format('ddd, MMM D')}
                        </Typography>
                        <Typography  className={classes.body1} variant='body1'>Players: {playerNames}</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} container direction="row">
                    <Grid item xs={12} >
                    {registration.event.eventTrainers.map((trainer) => {
                        return (<Trainer key={trainer.trainerId} style={{padding: '0.2rem'}} trainer={trainer} lead={trainer.trainerId === registration.event.leadTrainer}/>)
                    })}
                    </Grid>
                </Grid>
            </Grid>
            
        );
    }

    function RegistrationSuccess(props){
        return (
            <Grid container justify='center' style={{width: '80%', height: '40vh', backgroundColor: '#fffaf5', padding: '20px'}} direction="row">
                <Grid item>
                    <img src={domain.logo} alt="logo" style={{height: '120px'}}/>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='body1' align='center'>Success! You will receive an email confirmation shortly.</Typography>
                </Grid>
                <img src={check} alt="checkmark" style={{height: '80px'}} />
                <Grid item xs={12}>
                    <Link href={receiptUrl} variant='body1' target="_blank" color='inherit' style={{marginBottom: 20, textDecoration: 'underline'}}>
                        <StyledButton style={{width: '100%'}}>
                        View Receipt
                        </StyledButton>
                    </Link>
                </Grid>
            </Grid>
        );
    }

    function RegistrationFailure(props){
        return (
            <Grid container direction="row" spacing={10} alignItems="stretch" justify="space-evenly">
                <Grid item xs={12}>
                    <Typography variant='h2'>Registration Failed</Typography>
                </Grid>
                <Grid xs={12}>
                    <Typography variant='body1'>Unfortunately we failed to finalize your registration.  Please try again or contact an administrator if you have any additional issues.</Typography>
                </Grid>
            </Grid>
        );
    }

    const oldPaymentLook = () => {
        // TODO: add multiple saved cards
        // this UI is (partly) for when we have multiple cards 
        // so we don't need to rebuild it entirely
        return (
            <Grid container item spacing={2}>
                                
                                {/* "Add a Card" to add later once we can handle multiple CCs per user  */}
                                {/* Select or Add Credit Card */}
                                {/* <Grid container item xs={12} spacing={2} align='center'>
                                    <ToggleButtonGroup>
                                    {cards.map((card, id)=>{
                                        return (
                                            <ToggleButton key={id} disableFocusRipple={true} style={{margin: '5px'}} value={card.lastFour} selected={selectedCard === card} onChange={() => handleCardSelect(card.lastFour)}>
                                                <Grid item container xs={4}  style={{margin: '3px', marginLeft: '15px', height: '50px', borderRadius: '4px', border: '1px solid black'}} alignItems='center'>
                                                    <Grid item xs={3}>
                                                        {<FaRegCreditCard /> || <FaCreditCard/>}
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <Typography className={classes.body1}>Credit Card</Typography>
                                                    </Grid>
                                                </Grid>
                                            // </ToggleButton>
                                        )
                                    })}
                                    </ToggleButtonGroup>
                                    <Grid item container xs={4} style={{margin: '3px', height: '50px', borderRadius: '4px', border: '1px solid black'}} alignItems='center'>
                                        <Grid item xs={3}>
                                            <FaPlus />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Typography className={classes.body1}>Add a Card</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid> */}
    
                                {
                                    hasCardSaved
                                    ?
                                    <Grid item xs={12} container>
                                        <Typography className={classes.body2}>Card on file</Typography>
                                        <StyledInput className={classes.input} disabled value={paymentInfo?.name + ' ' + paymentInfo?.cc}><span style={{padding: '10px 8px'}}/></StyledInput>
                                        {/* <Grid item xs={12} style={{border: 'black 1px solid', borderRadius: '4px', }}> */}
                                    </Grid>
                                    :
                                    <Grid item xs={12} style={{height: '4.6rem'}}>
                                    </Grid>
                                }
                                <Grid item container spacing={1} xs={12}>
                                    <Grid item xs={6} container>
                                        <Grid item xs={12}>
                                            <Typography className={classes.body2}>Name on Card</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <StyledInput error={errors.name} name="name" className={classes.input}  disabled={hasCardSaved} value={paymentInfo?.name} onChange={(value) => setPaymentField('name', value)} placeholder={'Name'}/>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} container>
                                        <Grid item xs={12}>
                                            <Typography disabled={hasCardSaved} className={classes.body2}>Card Number</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <StyledInput error={errors.cc} name="cc" className={classes.input}  disabled={hasCardSaved} value={paymentInfo?.cc} onChange={(value) => setPaymentField('cc', value)} style={{fontFamily: 'sans serif'}} placeholder={'****'}></StyledInput>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item container spacing={1} xs={12}>
                                    {/* month */}
                                    <Grid item xs={5}>
                                        <StyledInput error={errors.month} name="month" className={classes.input}  disabled={hasCardSaved} value={paymentInfo?.month} placeholder="Month" select helperText='Month' onChange={(value) => setPaymentField('month', value)} fullWidth SelectProps={{MenuProps:{PaperProps:{style: {maxHeight: 150, width: '20ch'}}}}}>
                                            {months.map((month) => <SelectMenuItem key={month} value={month}>{month}</SelectMenuItem>)}
                                        </StyledInput>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <StyledInput error={errors.year} name="year" className={classes.input}  disabled={hasCardSaved} value={paymentInfo?.year} placeholder="Year" select helperText='Year' onChange={(value) => setPaymentField('year', value)} style={{textAlign: 'left'}} fullWidth SelectProps={{MenuProps:{PaperProps:{style: {maxHeight: 150, width: '20ch'}}}}}>
                                            {years.map((year) => <SelectMenuItem key={year} value={year}>{year}</SelectMenuItem>)}
                                        </StyledInput>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <StyledInput error={errors.cvv} className={classes.input} value={paymentInfo?.cvv} inputProps={{ maxLength: 3 }} placeholder="CVV" onChange={(value) => setPaymentField('cvv', value)}/>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={classes.body2}>Billing Address</Typography>
                                    <StyledInput error={errors.address} name="address" className={classes.input}  disabled={hasCardSaved} value={paymentInfo?.address} onChange={(value) => setPaymentField('address', value)} placeholder="Street, City, State, ZIP"/>
                                </Grid>
                                {
                                    hasCardSaved 
                                    ? 
                                    <Grid item xs={12} container spacing={1}>
                                        <Grid item xs={1}>
                                            <CircularProgress style={{marginTop: '-0.5rem', visibility: loading ? 'visible' : 'hidden'}} color="" />
                                        </Grid>
                                        <Grid item xs={hasCardSaved ? 6 : 12}>
                                            <StyledButton disabled={arePaymentFieldsComplete} onClick={()=>checkFieldsAndPay()} style={{width: '100%', borderRadius: '20px'}}>Pay with this card</StyledButton>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <StyledButton style={{ width: '100%'}} variant="light-border" onClick={()=>setHasCardSaved(false)}>Edit card</StyledButton>
                                        </Grid>
                                    </Grid>
                                    : 
                                    <Grid item xs={12}>
                                        <StyledButton onClick={()=>checkFieldsAndPay()} disabled={arePaymentFieldsComplete} style={{width: '100%', borderRadius: '20px'}}>Save card &#38; Pay</StyledButton>
                                    </Grid>
                                }
                            </Grid>
        )
    }

    /* end render functions */

    if (showConfirmation) {
        if(finalizeSuccess){
            return <StyledModal open={props.open} onClose={props.onClose} style={{maxWidth: '40%', minHeight: '12rem', marginTop: '2%'}}>
                    <RegistrationSuccess />
                </StyledModal>
        } else {
            return <RegistrationFailure />
        }            
    } else {
        return (
            <StyledModal open={props.open} onClose={props.onClose} style={{minWidth: '1000', minHeight: '36rem', marginTop: '2%'}}>
                <Grid xs={10} container direction="row" spacing={2} alignItems="stretch" justify="space-evenly" style={{margin: '1rem'}}>
                    <Grid container item xs={5}>
                        <RegistrationSummary registration={registration}/>
                    </Grid>
                    <Grid container item xs={7}>
                        <CollectPayment/>
                    </Grid>
                </Grid>
            </StyledModal>
        );
    } 
}

export default PaymentModal;
