import React from 'react';
import { useContext } from 'react';
import { Grid, Divider, Hidden, Box } from '@material-ui/core';
import { Instagram, Facebook, Twitter } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import AppContext from '../context';
import ColoredTypography from './ColoredTypography';
import AuthPair from './AuthPair';

function AppFooter() {
    const context = useContext(AppContext);
	const domain = context.domain;

	// TODO make these links to actual social media once the pages exist.
	function SocialMediaIcons() {
		return (
			<Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-start">
				<Grid item><Facebook /></Grid>
				<Grid item><Twitter /></Grid>
				<Grid item><Instagram /></Grid>
			</Grid>
		);
	}

	/* 
	Layout used commonly in the footer.
	Has 6 'holes' (as described here: https://reactjs.org/docs/composition-vs-inheritance.html) 
	in the following layout:
	|--------------------------|
	| 1                     2  |
	|--------------------------|
	| 3   4    5            6  |
	|                          |
	|--------------------------|
	Collapses to remove all the blank middle space for sufficiently small screens size sm-.
	*/    
	function FooterGrid({topLeft1, topRight2, bottomLeft3, bottomLeft4, bottomMiddle5, bottomRight6}) {
		return (
			<Grid item xs={11} container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
				<Grid item xs={12} container direction="row">
					<Grid item xs={6}>{topLeft1}</Grid>
					<Grid item xs={6}>{topRight2}</Grid>
				</Grid>
				<Grid item md={3} xs={4} container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>{bottomLeft3}</Grid>
				<Grid item md={3} xs={5} container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
					<Grid item xs={6}>{bottomLeft4}</Grid>
					<Grid item xs={6}>{bottomMiddle5}</Grid>
				</Grid>
				<Hidden smDown><Grid item md={3}></Grid></Hidden>
				<Grid item md={3} xs={3} container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>{bottomRight6}</Grid>
			</Grid>
		);
	}                     

	// Simple combination of grid item and colored typography with default color.
	// Just because w/o this some lines get dummy long.
	function FooterLabel(props) {
		// Only want to forward a subset of props to the childmost element
		const forwardProps = {...props};
		delete forwardProps.xs;
		return (
			<Grid item xs={props.xs}>
				<ColoredTypography {...forwardProps} color={props.color ?? "secondary.contrastText"}>
					{props.children}
				</ColoredTypography>
			</Grid>
		);
	}

	// Effectively a FooterLabel wrapped with an included link. 
	function FooterLink(props) {
		// Only want to forward a subset of props to the childmost element
		const forwardProps = {...props};
		delete forwardProps.xs;
		delete forwardProps.to;
		return (
			<Grid item xs={props.xs}>
				<Link to={props.to} style={{ textDecoration: 'none' }}> 
					<ColoredTypography {...forwardProps} color={props.color ?? "secondary.contrastText"}>
						{props.children}
					</ColoredTypography>
				</Link>
			</Grid>
		);
	}

	return (
		<Box style={{padding: 15}} bgcolor="secondary.main" color="secondary.contrastText">
			<Grid container direction="row" justify="center" alignItems="center">
				<Grid item xs={12} container direction="row"><Grid item xs={12}><Divider style={{marginBottom: 40}}/></Grid></Grid>
				<FooterGrid 
					topLeft1={
						<Grid container justify="flex-start" alignItems="flex-end">
							<Grid item><Link to="/"><img src={domain.logo} alt="logo" style={{height: '40px'}}/></Link></Grid>
						</Grid>
					}
					topRight2={
						<Grid container justify="flex-end" alignItems="flex-end">
							<AuthPair variant="inverted" justify="flex-end" xs={12}></AuthPair>
						</Grid>
					}
					bottomLeft3={
						<Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
							<FooterLink xs={12} to="/" variant="subtitle2" align="left" color="secondary.contrastTextDark">{domain.companyName}</FooterLink>
							{/* <Grid item md={6} xs={8}><SocialMediaIcons></SocialMediaIcons></Grid> */}
						</Grid>
					}
					bottomLeft4={
						<Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
							<FooterLabel variant="h5" color="secondary.contrastTextDark">Support</FooterLabel>
							<FooterLink to='#' variant='h5' onClick={(e) => {
										window.location.href = 'mailto:admin@soslaxcamp.org';
										e.preventDefault();
									}}
								></FooterLink>
							{/* TODO create page/modal for this to go to */}
							{/* <FooterLink to="/#" variant="h5">Contact</FooterLink>  */}
							{/* TODO create page/modal for this to go to */}
							{/* <FooterLink to="/#" variant='h5'>FAQ</FooterLink> */}
							{ context.user && 
								<FooterLink to="/profile" variant='h5'>Account</FooterLink>  
							}
						</Grid>
					}
					bottomMiddle5={
						<Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
							{/* <FooterLabel variant="h5" color="secondary.contrastTextDark">Other</FooterLabel> */}
							{/* <FooterLink to="/about" variant='h5'>About</FooterLink>  */}
							{/* <FooterLink to="/upcoming" variant='h5'>Clinics</FooterLink>  */}
							<FooterLink to="/trainers" variant='h5'>Camp Staff</FooterLink> 
						</Grid>
					}
				></FooterGrid>
				<Grid item xs={12} container direction="row"><Box py={2}/></Grid>
				<FooterGrid
					bottomLeft3={
						// TODO I'm not a lawyer, but I think this copyright should actually be true
						<Grid item xs={12} container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
							<FooterLabel variant="h6" align="left" color="secondary.contrastTextDark">© Copyright 2022 • {domain.companyName}</FooterLabel>
						</Grid>
					}
					bottomLeft4={
						<Grid item xs={12} container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
							<FooterLink align="left" variant="h6" color="secondary.contrastTextDark" to="/privacy">Privacy Policy</FooterLink> {/* TODO create page/modal for this to go to */}
						</Grid>
					}
					bottomMiddle5={
						<Grid item xs={12} container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
							<FooterLink align="left" variant="h6" color="secondary.contrastTextDark" to="/terms">Terms of Use</FooterLink> {/* TODO create page/modal for this to go to */}
						</Grid>
					}
				></FooterGrid>
			</Grid>
		</Box>
	);
}

export default AppFooter;
