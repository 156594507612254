import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, useHistory } from 'react-router-dom';
import './App.css';
import { ThemeProvider } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';
import Home from './pages/Home';
import About from './pages/About';
import Trainers from './pages/Trainers';
import Trainer from './pages/Trainer';
import Upcoming from './pages/Upcoming';
import Payment from './pages/Payment';
import Event from './pages/Event';
import Profile from './pages/Profile';
import AuthPage from './pages/AuthPage';
import NotFound from './pages/NotFound';
import Terms from './pages/Terms';
import CampMission from './pages/CampMission';
import AppContext from './context';
import AppFooter from './components/AppFooter';
import AppHeader from './components/AppHeader';
import SosPlayerPage from './components/SosPlayerPage';
import pmpLogo from './assets/pmpLogo.png'
import sosLogo from './assets/sos-logo.png'
import filler from './assets/fillerIMG.png';
import pmpBanner from './assets/pmp_banner.jpeg';
import sosBanner from './assets/sos_banner.jpeg';
import pmpBottomBanner from './assets/climbing_wall.jpeg';
import sosBottomBanner from './assets/pmp_bottom_banner.jpeg';
import theme from './utils/pmp_theme';
import pmp_axios from './utils/pmp_axios';
import { Redirect } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';

const currentUrl = window.location.hostname;
console.log('currentUrl');
console.log(currentUrl);

function App() {
  const [user, setUser] = useState();

  const [domain, setDomain] = useState({
    host: '',
    companyName: '',
    abbreviation: '',
    logo: filler,
    tagline: '',
    about: '',
    contact: '',
    homeMainBanner: '',
    mainBannerPrimaryTagLine:'',
    mainBannerPrimaryHeader:'',
    mainBannerPrimaryButtonLabel:'',
    bottomBannerImage: '',    
    bottomBannerTitle:'',
    bottomBannerSubTitle:'',
    bottomBannerLinkText:'',
    trainerBanner: '',
    siteTitle: '',
    favIcon: '',
  });



  const history = useHistory();

  const get_profile = async () => {
    return await pmp_axios.get('/user/profile', (res)=>res.data.profile);
  };

  useEffect(()=>{
    if (currentUrl === 'pmpsports.live' || currentUrl === 'www.pmpsports.live') {
      setDomain({
        host: 'pmp',
        companyName: 'Practice Makes Permanent',
        abbreviation: 'PMP',
        logo: pmpLogo,
        tagline: '',
        about: 'Lorum Ipsum',
        contact: 'contact@pmpsports.live',
        homeMainBanner: pmpBanner,
        mainBannerPrimaryTagLine: 'Enabling trainers to connect with players!',
        bottomBannerImage: pmpBottomBanner,
        bottomBannerSubTitle:'',
        bottomBannerLinkText:'',
        trainerBanner: '',
        siteTitle: 'Practice Makes Permanent',
        favIcon: '',
      })
    } else if(currentUrl === 'soslaxcamp.org' || currentUrl === 'localhost' || currentUrl === 'www.soslaxcamp.org') {
      setDomain({
        host: 'sos',
        companyName: 'SOS Lacrosse Camp',
        abbreviation: 'SOS',
        logo: sosLogo,
        tagline: 'Southern Ohio Shootout Lacrosse Camp',
        about: 'Lorum Ipsum',
        contact: 'contact@soslaxcamp.org',
        homeMainBanner: sosBanner,
        mainBannerPrimaryTagLine: 'Come learn from the top PLL, NLL and NCAA Division 1 Lacrosse Players as well as leading collegiate coaches!',
        mainBannerPrimaryHeader: 'Southern Ohio Shootout Lacrosse Camp',
        mainBannerPrimaryButtonLabel:'SIGN UP NOW',
        bottomBannerImage: sosBottomBanner,
        bottomBannerTitle:'Come learn from some of the best in the country',
        bottomBannerSubTitle:'',
        bottomBannerLinkText:'Sign Up Now',
        trainerBanner: '',
        siteTitle: 'Ohio\'s Premier Lacrosse Camp',
        favIcon: '',
      });
    }
    console.log('rerendering check');
    
    document.title = domain.siteTitle
    
  },[]);
  console.log('rerendering check3');

  if (localStorage.getItem('refreshToken') && localStorage.getItem('accessToken') && !user) {
    get_profile().then((profile_res)=>{
      if (profile_res.success) {
        setUser(profile_res.parsed_result);
      } else {
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('accessToken');
      }
    })
  }

  function AppBody() {
    if (user) {
      // authenticated user routes
      return (
        <Switch>
          <Route path="/" component={()=><Home domain={domain} />} exact />
          <Route path="/about" component={About} />
          <Route path="/upcoming" component={Upcoming} />
          <Route path="/trainers" component={()=><Trainers domain={domain} />} />
          <Route path="/trainer" component={Trainer} />
          <Route path="/profile" component={Profile} />
          <Route path="/payment" component={Payment} />
          <Route path="/event/*" component={()=> <Event history={history}/>} />
          <Route path="/auth" component={AuthPage} />
          <Route path="/terms" component={Terms} />
          <Route path="/privacy" component={Terms} />
          <Route path="/camp-mission" component={CampMission} />
          <Route path="/player-register" component={SosPlayerPage} />
          <Route path="*" component={NotFound} />
        </Switch>
      );
    } else {
      // unauthenticated user routes
      return (
        <Switch>
          <Route path="/" component={()=><Home domain={domain} />} exact />
          <Route path="/about" component={About} />
          <Route path="/upcoming" component={Upcoming} />
          <Route path="/trainers" component={() => <Trainers domain={domain} />} />
          <Route path="/trainer" component={Trainer} />
          <Route path="/event/*" component={()=> <Event history={history}/>} />
          <Route path="/profile" component={Profile} />
          <Route path="/auth" component={AuthPage} />
          <Route path="/terms" component={Terms} />
          <Route path="/privacy" component={Terms} />
          <Route path="/camp-mission" component={CampMission} />
          <Route path="/player-register" component={()=> <Redirect to="/auth" />} />
          <Route path="*" component={NotFound} />
        </Switch>
      );
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <AppContext.Provider value={{ user, setUser, domain }} >
        <Router>
          <Grid container justify="center" alignItems="stretch" className="App">
            <Grid container item alignItems="flex-start">
              <Grid item xs={12}>
                <AppHeader domain={domain}/>
              </Grid>
            </Grid>
            <Grid container item xs={12} direction="row" alignItems="center" justify="center">
              <Grid item xs={12}>
                <AppBody />
              </Grid>
            </Grid>
            <Grid container item alignItems="flex-end" xs={12}>
              <Grid item xs={12}>
                <AppFooter />
              </Grid>
            </Grid>
          </Grid>
            <div style={{position: 'fixed', width: '100%', zIndex: -1}}><Box bgcolor="secondary.main" style={{height: '100vh'}}/></div> {/* fancy way to make the area below the footer gray */}
        </Router>
      </AppContext.Provider>
    </ThemeProvider>
  );
}

export default App;


