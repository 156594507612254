import React, { useContext, useState } from 'react';
import AppContext from '../context';
import StyledButton from './StyledButton';
import AuthModal from './AuthModal';
import { Redirect } from 'react-router-dom';

function AuthButton(props) {
  const context = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const [signOut, setSignOut] = useState(false);

  /* begin event functions */
  const handle_button_click = () => {
    if (!context.user) {
      setOpen(true);
    } else {
      setSignOut(true);
    }
  }
  /* end event functions */

  if (signOut) {
    context.setUser(null);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    return (<Redirect push to={'/'} />);
  } else {
    return (
      <div>
        <AuthModal open={open} handleClose={() => setOpen(false)}/>
        <StyledButton onClick={handle_button_click} {...props}>
          {props.children ? props.children : context.user ? 'SIGN OUT' : 'SIGN IN'}
        </StyledButton>
      </div>
    );
  }
}

export default AuthButton;
