import React, { useContext, useState, useRef } from 'react';
import { Grid, Typography, Link, CircularProgress, Checkbox, useTheme, makeStyles, FormControl, Select, MenuItem, InputLabel, SelectMenuItem } from '@material-ui/core';
import AppContext from '../context';
import StyledButton from './StyledButton';
import StyledModal from '../components/StyledModal';
import StyledInput from './StyledInput';
import pmp_axios from '../utils/pmp_axios'
import { Redirect } from 'react-router-dom';

function SosPlayerPage(props) {
  const context = useContext(AppContext);  const setOpen = props.setOpen;
  const selectedPlayer = props.player;
  const domain = context.domain;
  const playersWithInfoNeeded = [];  
  const [toggle, setToggle] = useState(false);
  const [formInfo, setFormInfo] = useState([]);
  const [signInEmail, setSignInEmail] = useState();
  const [signInPassword, setSignInPassword] = useState();
  const [submitErrorText, setSubmitErrorText] = useState();
  const [signInErrorText, setSignInErrorText] = useState();
  const [loading, setLoading] = useState(false);
  const [skillOpen, setSkillOpen] = useState(false);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [skills, setSkills] = useState([]);

  const theme = useTheme();
  const skillRef = useRef();

  const useStyles = makeStyles( theme => ({
    root: {
        border: `1px solid ${theme.palette.secondary.main}`, 
        backgroundColor: theme.palette.primary.main, 
        borderRadius: 10, 
        height: 120,
        boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.1)',
    },
    filterType: {
      borderRadius: 10,
      '&:hover': {
        border: `1px solid ${theme.palette.primary.main}`,
        boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.08)',
      },
      '&:active': {
        border: `1px solid ${theme.palette.secondary.main}`,
        boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.08)',
      },
    },
    calendar: {
        backgroundColor: theme.palette.primary.main,
        width: '305px',
        height: '310px',
        padding: '1rem',
        lineHeight: '1.5',
        fontFamily: 'Arial',
        border: '1px solid black',
        borderRadius: '10px'
    },
    skillPill: {
        border: `1px solid ${theme.palette.secondary.main}`, 
        borderRadius: 5, 
        cursor: 'pointer',
        verticalAlign: 'middle',
    },
    skillPillText: {
        fontSize: '0.45rem',
        fontFamily: 'Arial Narrow',
    },
    dropdownOption: {
        padding: '0.5rem',
        '&:hover': {
            backgroundColor: 'black',
            color: 'white',
            cursor: 'pointer',
        },
        '&:hover *': {
            color: theme.palette.primary.main,
        }
    },
    dropdownOptionText: {
        fontFamily: 'Arial',
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
}));

  const classes = useStyles();

  /* begin API functions */
  const post_guardian = async () => {
    const guardianObj = {
      first: formInfo.guardianFirst,
      last: formInfo.guardianLast,
      email: formInfo.email,
      password: formInfo.password,
    };
    return await pmp_axios.post('/user/guardian', guardianObj, (res)=>res.data.user);
  }

  const post_player = async () => {
    const playerObj = selectedPlayer;
    const playerId = selectedPlayer.id;
    return await pmp_axios.patch('/user/profile', playerObj, (res)=>res.player);
  }

  const post_signin = async () => {
    const signInObj = {
      email: signInEmail,
      password: signInPassword 
    };
    return await pmp_axios.post('/user/signIn', signInObj, (res)=>res.data.user);
  };

  const get_profile = async () => {
    return await pmp_axios.get('/user/profile', (res)=>res.data.profile);
  };
  /* end API functions */

  /* begin helper functions */
  const update_form_info = (id, field, value) => {
    if(value !== ''){
      const infoToAdd = {
        id: id,
        fieldName: field,
        fieldValue: value.toString()
    };
    const index = formInfo.findIndex(f => f.id === infoToAdd.id);
    if(index > 0){
      formInfo.splice(index, 1);
    }
    
    setFormInfo([...formInfo, infoToAdd]);  
    console.log(formInfo)
    }

  }

  const signin = async () => {
    const signin_res = await post_signin();
    if (signin_res.success) {
      localStorage.setItem("accessToken", signin_res.parsed_result.accessToken);
      localStorage.setItem("refreshToken", signin_res.parsed_result.refreshToken);
      const profile_res = await get_profile();
      if (profile_res.success) {
        context.setUser(profile_res.parsed_result);
        return 'success';
      } else {
        return 'Failed to get user info, please contact support.'
      }
    } else {
      return signin_res.error;
    }
  }

  const signup = async () => {
    const guardian_res = await post_guardian();
    if (guardian_res.success) {
      localStorage.setItem("accessToken", guardian_res.parsed_result.accessToken);
      localStorage.setItem("refreshToken", guardian_res.parsed_result.refreshToken);
    } else {
      return guardian_res.error;
    }

    const player_res = await post_player();
    if (player_res.success) {
      // be happy there was no error
    } else {
      return player_res.error;
    }

    const profile_res = await get_profile();
    if (profile_res.success) {
      context.setUser(profile_res.parsed_result);
      return 'success';
    } else {
      return 'Failed to get user info, please contact support.';
    }
  };

  const validate_submission = () => {    
    const waiverChecked = formInfo[formInfo.findIndex(i => i.fieldName === "waiverChecked")];
    if (!waiverChecked.fieldValue) return 'You must sign the waiver to register your player.';
    return 'valid';
  }

  const update_skills = (i) => {
    const index = selectedSkills.indexOf(skills[i]);
    if (index > -1) {
        const updatedSkills = [...selectedSkills.slice(0, index).concat(selectedSkills.slice(index + 1))]
        setSelectedSkills(updatedSkills);
    } else {
        if (selectedSkills.length > 2) selectedSkills.pop();
        const updatedSkills = [...selectedSkills, skills[i]]
        setSelectedSkills(updatedSkills);
    }
}

  const create_payload = () => {    
    selectedPlayer.additionalFields = formInfo;
    //Make request on behalf of this player
    selectedPlayer.userId = selectedPlayer.id;
  }
  
  /* end helper functions */

  /* begin event functions */
  const handle_submission = async () => {
    setLoading(true);
    const valid = validate_submission();
    if (valid === 'valid') {
      create_payload();
      const submit_text = await post_player();
      if (submit_text.success) {
        handle_close();
        setSubmitErrorText(null);
        setFormInfo({});        
      } else {
        setSubmitErrorText(submit_text);
      }
    } else {
      setSubmitErrorText(valid);
    }
    setLoading(false);
  }

  const handle_close = () => {
    setFormInfo({});
    setToggle(false);
    setSignInEmail('');
    setSignInPassword('');
    setSignInErrorText(null);
    setSubmitErrorText(null);
    props.handleClose();
  }  

  /* end event functions */

  /* begin rendering functions */
  function AdditionalInfo() {    
      return (
        selectedPlayer ?
          <StyledModal open={props.open} onClose={props.handleClose} style={{minWidth:"1000", minHeight:"36rem", maxWidth:"60vw", maxHeight:"80vh", marginTop:"10px", overflow:"scroll"}}>
            <Grid container justify='center' alignItems="center" spacing={3}>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <Grid item xs={12}>
                <Typography variant="h4" align="center">Complete player registration for {selectedPlayer.first} </Typography>
              </Grid>
              <Grid item xs={8}>
                <StyledInput onBlur={v => update_form_info(1,'guardianPhone', v)} value={formInfo.parentPhone} placeholder='Guardian Phone Number'/>
              </Grid>
              <Grid item xs={8}>
                <StyledInput onBlur={v => update_form_info(2,'playerGrade', v)} value={formInfo.playerGrade} placeholder="Player's Grade in Fall of 2024"/>
              </Grid>
              <Grid item xs={8}>
                <StyledInput onBlur={v => update_form_info(3,'playerShoeSize', v)} value={formInfo.playerShoeSize} placeholder="Player's Shoe Size"/>
              </Grid>
              <Grid item xs={8}>
                <StyledInput onBlur={v => update_form_info(4,'playerShirtSize', v)} value={formInfo.playerShirtSize} placeholder="Player's Shirt Size"/>
              </Grid>
              <Grid item xs={8}>
                <FormControl fullWidth>
                  <StyledInput label="Player Position" select value={formInfo.playerPosition} onBlur={(value) => update_form_info(5,'playerPosition', value)} style={{textAlign: 'left'}} fullWidth SelectProps={{MenuProps:{PaperProps:{style: {maxHeight: 200, width: '20ch'}}}}}>
                    <MenuItem value={'Attack'}>Attack</MenuItem>
                    <MenuItem value={'Midfield'}>Midfield</MenuItem>
                    <MenuItem value={'Defense'}>Defense</MenuItem>
                    <MenuItem value={'Goalie'}>Goalie</MenuItem>
                  </StyledInput>
                </FormControl>
              </Grid>
              <Grid item xs={8}>
                <StyledInput onBlur={v => update_form_info(6,'emergencyContact', v)} value={formInfo.emergencyContact} placeholder="Emergency Contact (if different from Guardian)"/>
              </Grid>
              <Grid item xs={8}>
                <StyledInput onBlur={v => update_form_info(7,'healthInsuranceAndGroupNumber', v)} value={formInfo.healthInsuranceAndGroupNumber} placeholder="Health Insurance Provider and Group Number"/>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" align="center"><Checkbox onChange={v => update_form_info(8,'waiverChecked', !formInfo.waiverChecked)} /><Link href="/privacy" target="_blank" color="inherit" style={{textDecoration: 'underline', cursor: 'pointer'}} >Waiver and Agree to Terms</Link></Typography>
              </Grid>
              {signInErrorText &&
                <Grid item>
                  <Typography variant='body2' style={{color: 'red'}}>{signInErrorText}</Typography>
                </Grid>
              }
              <Grid item xs={1}>
                  <CircularProgress style={{marginTop: '-0.5rem', visibility: loading ? 'visible' : 'hidden'}} color="" />
                </Grid>
              <Grid item xs={8}>
                <StyledButton fullWidth onClick={() => handle_submission()} style={{minHeight: 40}} disabled={formInfo?.count < 7}>Submit</StyledButton>
              </Grid>
            </Grid>
          </StyledModal>
          :
          ""
      );
  }
  /* end rendering functions */

  return (
    toggle ? <Redirect to="/auth" /> : AdditionalInfo()
  );
}

export default SosPlayerPage;
