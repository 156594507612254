import React, { useState } from 'react';
import { Grid, Typography, Hidden } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import StyledButton from '../components/StyledButton';
import image from '../assets/fillerIMG.png';

function NotFound() {
  const [redirect, setRedirect] = useState();

  if (redirect) {
    return <Redirect push to={{ pathname: "/"}}/>;
  } else {
    return (
      <Grid container>
        <Grid container item lg={5} md={12} spacing={3}>
          <Grid item xs={12}>
            <Typography variant='h2' align='left'>Not Found</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1' align='justify'>We're sorry, we couldn't find that page.</Typography>
          </Grid>
          <Grid item xs={3}>
            <StyledButton onClick={() => setRedirect(true)}>RETURN HOME</StyledButton>
          </Grid>
        </Grid>
        <Hidden mdDown><Grid item xs={7}>
          <img style={{border: '2px solid black', maxHeight: '100%'}} alt="placeholder" src={image}/>
        </Grid></Hidden>
      </Grid>
    );
  }
}

export default NotFound;
