import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Grid, ListItem, Typography, List } from '@material-ui/core';
import EventsFilter from '../components/EventsFilter';
import StyledButton from '../components/StyledButton';
import Content from '../components/Content';

function CampMission() {

    return(
        <Content>
            <Grid container justify="center" alignItems="center">
                <Grid item md={8} container direction="column" alignItems="center" spacing={2}>
                    <Grid item>
                        <Typography variant="h1" align="center">Camp Mission</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" align="center">     
                        Southern Ohio Shoot-Out Camp understands that our camp is instructional and we strive to teach the finer points of the game. Campers are engaged in a full day of activities, designed to give them a wonderful and complete experience. Our premier staff is committed to improving each player’s individual skill set.                   
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" align="center">
                            While many camps emphasize games, we stress skill development through high quality instruction, that is reinforced by competition. The camp offers a program that is designed to improve all aspects of the player’s game, taught by some of the top players and coaches in the game. Camper to staff ratio is capped at 9:1 to ensure small group instruction.<br/>
							<br/>The camp is organized into proper age and ability groups. Each camper will receive the same top-level instruction, regardless of his lacrosse experience.
                        </Typography>
                    </Grid>   
                    <Grid item>
                        <Typography variant="h3" align="center">Camp Focuses</Typography>
                    </Grid>
                    <Grid container>                    
                        <Grid item md={6}>                            
                            <List>
                                <ListItem>Shooting</ListItem>
                                <ListItem>Ground Balls</ListItem>
                                <ListItem>Dodging</ListItem>
                                <ListItem>Stick Skills</ListItem>
                                <ListItem>Face-offs</ListItem>                        
                                <ListItem>Defense (Team &amp; Individual)</ListItem>
                            </List>
                        </Grid>
                        <Grid item md={6}>
                            <List>
                                <ListItem>Strategy &amp; Tactics</ListItem>
                                <ListItem>Goalie Techniques &amp; Training</ListItem>
                                <ListItem>Individual Position Training</ListItem>
                                <ListItem>Box Lacrosse Techniques &amp; Strategy</ListItem>
                                <ListItem>Offensive Strategy</ListItem>
                                <ListItem>Clears &amp; Rides</ListItem>
                                <ListItem>Man-Up &amp; Man-Down</ListItem>
                            </List>
                        </Grid>
                    </Grid>                 
                </Grid>
            </Grid>
        </Content>
    )

}
export default CampMission;