import { useContext } from 'react';
import AppContext from '../context';

import { Grid, Box } from '@material-ui/core';
import { Person } from '@material-ui/icons';
import { Link } from 'react-router-dom';

import AuthButton from './AuthButton';
import PaletteColorizer from '../utilities/PaletteColorizer';



// The person icon and auth button
function AuthPair({md, xs, justify, variant}) {
    const context = useContext(AppContext);

    function ColoredPersonIcon(props) {
        return <PaletteColorizer component={<Person></Person>} colorProperty="color" {...props}></PaletteColorizer>
    }

    let iconColor;
    switch (variant) {
        default: case 'dark':
            iconColor = "secondary.dark";
            break;
        case 'light':
            iconColor = "secondary.dark"
            break;
        case 'inverted':
            iconColor = "secondary.contrastTextDark";
            break;
    }

    return (
        <Grid item md={md ?? 3} xs={xs ?? 6} container direction="row" justify={justify} alignItems="center" spacing={1}>
			<Grid item>
				{/* Only god knows why it's needed, but the icon isn't vertically centered without additional padding */}
				<Box pt={0.5}>
                    { context.user 
                        ?   <Link to="/profile"><ColoredPersonIcon color={iconColor} fontSize="large"/></Link>
                        :   <ColoredPersonIcon color={iconColor} fontSize="large"/>
                    }
                </Box>
			</Grid>
			<Grid item>
				<AuthButton variant={variant}/>
			</Grid>
		</Grid>
	);
}



export default AuthPair;