import React, { useState, useEffect, useRef, useContext } from 'react'
import { Grid, Popper, Typography, Paper, makeStyles, useTheme } from '@material-ui/core';
import { DatePickerCalendar } from 'react-nice-dates';
import pmp_axios from '../utils/pmp_axios'
import * as dayjs from 'dayjs'
import { enGB } from 'date-fns/locale'
import SearchIcon from '@material-ui/icons/Search';
import AppContext from '../context';

import 'react-nice-dates/build/style.css';
import '../utils/calendar.css'
/*
    TODO: replace calendar.css with localized styling that can use pmp_theme.js.
    Suggest looking into package "styled-components" and wrapping the calendars
    with a styled.div that can take react-nice-dates' classes.
    See https://codesandbox.io/s/react-nice-dates-forked-5nyyd?file=/src/index.js for demo.
*/

const useStyles = makeStyles( theme => ({
    root: {
        border: `1px solid ${theme.palette.secondary.main}`, 
        backgroundColor: theme.palette.primary.main, 
        borderRadius: 10, 
        height: 120,
        boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.1)',
    },
    filterType: {
      borderRadius: 10,
      '&:hover': {
        border: `1px solid ${theme.palette.primary.main}`,
        boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.08)',
      },
      '&:active': {
        border: `1px solid ${theme.palette.secondary.main}`,
        boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.08)',
      },
    },
    calendar: {
        backgroundColor: theme.palette.primary.main,
        width: '305px',
        height: '310px',
        padding: '1rem',
        lineHeight: '1.5',
        fontFamily: 'Arial',
        border: '1px solid black',
        borderRadius: '10px'
    },
    skillPill: {
        border: `1px solid ${theme.palette.secondary.main}`, 
        borderRadius: 5, 
        cursor: 'pointer',
        verticalAlign: 'middle',
    },
    skillPillText: {
        fontSize: '0.45rem',
        fontFamily: 'Arial Narrow',
    },
    dropdownOption: {
        padding: '0.5rem',
        '&:hover': {
            backgroundColor: 'black',
            color: 'white',
            cursor: 'pointer',
        },
        '&:hover *': {
            color: theme.palette.primary.main,
        }
    },
    dropdownOptionText: {
        fontFamily: 'Arial',
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
}));

function EventsFilter(props) {
    const default_filters = props.defaultFilters;
    
    const classes = useStyles();
    const theme = useTheme();
    const context = useContext(AppContext);

    const [startDateOpen, setStartDateOpen] = useState(false);
    const [endDateOpen, setEndDateOpen] = useState(false);
    const [ageOpen, setAgeOpen] = useState(false);
    const [skillOpen, setSkillOpen] = useState(false);

    const [startDate, setStartDate] = useState(default_filters ? default_filters.startDate : dayjs());
    const [endDate, setEndDate] = useState(default_filters ? default_filters.endDate : dayjs().add(1, 'month'));
    const [age, setAge] = useState(default_filters ? default_filters.age : null);
    const [selectedSkills, setSelectedSkills] = useState(default_filters ? [default_filters.skill] : []);
    const [ages, setAges] = useState([]);
    const [skills, setSkills] = useState([]);

    const startDateRef = useRef();
    const endDateRef = useRef();
    const ageRef = useRef();
    const skillRef = useRef();

    const { onEventsChange, onSubmission, updateMode } = props;
    const partial = updateMode === 'partial';
    const firstLoad = useRef(true);

    useEffect(() => {
        if ((partial || firstLoad.current) && onEventsChange) update_events();
        firstLoad.current = false;
    }, [startDate, endDate, age, selectedSkills]);

    useEffect(() => {
        set_event_filters();
    }, []);

    /* begin API functions */
    const get_event_filters = async () => {
        return await pmp_axios.get('/event/filters', (res)=>{return {skills: res.data.filters.skills, ages: res.data.filters.ageRanges, sex: res.data.filters.sex}});
    }

    const get_events = async () => {
        const playerObj = {
            startDate: startDate.toDate(),
            endDate: endDate.toDate(),
            skills: selectedSkills[0] && selectedSkills[0].id ? selectedSkills.map((skill) => skill.id) : [],
            ages: age ? [age.id] : [], // TODO allow multiple ages to be selected
            locations: [] // TODO add
        };
        return await pmp_axios.post('/event/list', playerObj, (res)=>res.data.events);
    }
    /* end API functions */

    /* begin helper functions */
    const set_event_filters = async () => {
        const events_res = await get_event_filters();
        if (events_res.success) {
            const filters = events_res.parsed_result;
            setSkills(filters.skills);
            setAges(filters.ages);
            // setSex(filers.sexes); TODO add gender
        } else {
            // TODO handle error
        } 
    }

    const update_events = async () => {
        const events_res = await get_events();
        if (events_res.success) {
            onEventsChange(events_res.parsed_result);
        } else {
            // TODO handle error
        } 
    }

    const update_skills = (i) => {
        const index = selectedSkills.indexOf(skills[i]);
        if (index > -1) {
            const updatedSkills = [...selectedSkills.slice(0, index).concat(selectedSkills.slice(index + 1))]
            setSelectedSkills(updatedSkills);
        } else {
            if (selectedSkills.length > 2) selectedSkills.pop();
            const updatedSkills = [...selectedSkills, skills[i]]
            setSelectedSkills(updatedSkills);
        }
    }

    /*  
        When user opens one dropdown, the others close 
        (to prevent overlap, and simply is expected behavior).
    */
    const close_other_dropdowns = (dropdownRef) => {
        if(dropdownRef !== startDateRef) {
            setStartDateOpen(false);
        }
        if(dropdownRef !== endDateRef) {
            setEndDateOpen(false);
        }
        if(dropdownRef !== ageRef) {
            setAgeOpen(false);
        }
        if(dropdownRef !== skillRef) {
            setSkillOpen(false);
        }
    }
    /* end helper functions */

    /* begin render functions */
    function SkillsBox(props) {
        return (
            <Grid container justify='space-around' alignContent='stretch' style={{height: '100%'}}>
                <Grid item xs={12}/>
                <Grid item xs={12} onClick={() => {setSkillOpen(!skillOpen); close_other_dropdowns(skillRef)}}>
                    <Typography style={{textAlign:'left', color: '#aeaeae', marginLeft: '15px'}} variant='body1'>Skills</Typography>
                </Grid>
                <Grid item xs={12}>
                    {selectedSkills.length === 0
                        ? <Typography style={{textAlign:'left', color: '#aeaeae', marginLeft: '15px', fontSize: '0.9rem'}} variant='body2'>Add up to three</Typography>
                        : <Grid container justify='space-around' spacing={1}>{selectedSkills.map((skill, i) =>
                            <Grid item xs={3} style={{border: `1px solid ${theme.palette.secondary.main}`, borderRadius: 5, cursor: 'pointer'}} onClick={() => {setSelectedSkills(selectedSkills.slice(0,i).concat(selectedSkills.slice(i+1)))}}>
                                <Typography style={{fontSize: '0.5rem', letterSpacing: '-0.25px', alignItems: 'center'}} variant='body2'>{skill ? skill.name : null }</Typography>
                            </Grid>
                        )}</Grid>
                    }
                </Grid>
            </Grid>
        );
    }
    /* end render functions */

    if(context.domain.host !== 'pmp') return null
    else return(
        <Grid container justify='space-evenly' alignContent='stretch' className={classes.root}>
            <Grid container item xs={partial ? 12 : 11} alignContent='stretch'>
                <Grid item xs={3} ref={startDateRef} className={classes.filterType}>
                    <Grid onClick={() => {setStartDateOpen(!startDateOpen); close_other_dropdowns(startDateRef)}} container justify='space-around' alignContent='stretch' style={{height: '100%'}}>
                        <Grid item xs={12}/>
                        <Grid item xs={12}>
                            <Typography style={{textAlign:'left', color: '#aeaeae', marginLeft: '15px'}} variant='body1'>Start date</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{textAlign:'left', marginLeft: '15px'}} variant='body2'>{startDate.format('MMM DD, YYYY')}</Typography>
                        </Grid>
                    </Grid>
                    <Popper 
                        placement="top-start" // "top-start" needs to be paired with disablePortal=false
                        disablePortal={false} // This calendar should probably be replaced after MVP is launched.
                        className={classes.calendar} 
                        open={startDateOpen} 
                        anchorEl={startDateRef.current}>
                            <DatePickerCalendar
                                date={startDate.toDate()} 
                                minimumDate={dayjs().toDate()} 
                                maximumDate={endDate.toDate()} 
                                onDateChange={(date) => setStartDate(dayjs(date))}
                                locale={enGB}
                            />
                    </Popper>
                </Grid>
                <Grid item xs={3} ref={endDateRef} className={classes.filterType}>
                    <Grid onClick={() => {setEndDateOpen(!endDateOpen); close_other_dropdowns(endDateRef)}} container justify='space-around' alignContent='stretch' style={{height: '100%'}}>
                        <Grid item xs={12}/>
                        <Grid item xs={12}>
                            <Typography style={{textAlign:'left', color: '#aeaeae', marginLeft: '15px'}} variant='body1'>End date</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{textAlign:'left', marginLeft: '15px'}} variant='body2'>{endDate.format('MMM DD, YYYY')}</Typography>
                        </Grid>
                    </Grid>
                    <Popper 
                        placement="top-start" // "top-start" needs to be paired with disablePortal=false
                        disablePortal={false} // This calendar should probably be replaced after MVP is launched.
                        className={classes.calendar} 
                        open={endDateOpen} 
                        anchorEl={startDateRef.current}> {/* this line is so the Popper appears in the same place as start calendar. */}
                        <DatePickerCalendar
                            date={endDate.toDate()}
                            minimumDate={startDate.toDate()}
                            maxmaximumDate={dayjs().add(1, 'year').toDate()}
                            onDateChange={(date) => setEndDate(dayjs(date))}
                            locale={enGB}
                        />
                    </Popper>
                </Grid>
                <Grid item xs={3} ref={ageRef} onClick={() => {setAgeOpen(!ageOpen); close_other_dropdowns(ageRef)}} className={classes.filterType}>
                    <Grid container justify='space-around' alignContent='stretch' style={{height: '100%'}}>
                        <Grid item xs={12}/>
                        <Grid item xs={12}>
                            <Typography style={{textAlign:'left', color: '#aeaeae', marginLeft: '15px'}} variant='body1'>Age range</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {age ? 
                                <Typography style={{textAlign:'left', marginLeft: '15px'}}  variant='body2'>{age.description}</Typography>
                                : 
                                <Typography style={{textAlign:'left', color: '#aeaeae', marginLeft: '15px'}}  variant='body2'>Set age</Typography>
                            }
                        </Grid>
                    </Grid>
                    <Popper open={ageOpen} anchorEl={ageRef.current}>
                        <Paper variant='outlined' style={{backgroundColor: theme.palette.primary.main, width: ageRef.current?.offsetWidth}}>
                            <Grid container justify='flex-start'>
                                {ages ? ages.map((age, i) => <Grid key={i} className={classes.dropdownOption} item xs={12}><Typography className={classes.dropdownOptionText} variant='h6' onClick={() => setAge(ages[i])}>{age.description}</Typography></Grid>) : null}
                            </Grid>
                        </Paper>
                    </Popper>
                </Grid>
                <Grid item xs={3} className={classes.filterType} ref={skillRef}>
                    <SkillsBox/>
                    <Popper open={skillOpen} anchorEl={skillRef.current} placement="top-start" disablePortal={false}>
                        <Paper variant='outlined' style={{backgroundColor: theme.palette.primary.main, width: skillRef.current?.offsetWidth}}>
                            <Grid container justify='flex-start'>
                                {skills.map((skill, i) => <Grid className={classes.dropdownOption} key={i} item xs={12}>
                                    <Typography className={classes.dropdownOptionText} variant='h6' onClick={() => update_skills(i)}>{skill.name}</Typography>
                                </Grid>)}
                            </Grid>
                        </Paper>
                    </Popper>
                </Grid>
            </Grid>
            { !partial &&
            <Grid item xs={1} style={{margin: 'auto'}}>
                <SearchIcon style={{cursor: 'pointer', backgroundColor: theme.palette.secondary.main, borderRadius: 5, padding: 4, boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.15)'}} fontSize='large' color='primary' onClick={() => onSubmission({startDate: startDate, endDate: endDate, age: age, skills: selectedSkills})}/>
            </Grid>}
        </Grid>
    );
}
export default EventsFilter;
 