import React from 'react';
import { Grid, makeStyles, Dialog, DialogContent } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'white',
    width: '800px',
    height: 'auto',
    margin: 'auto',
    alignItems: 'center',
    outline: 'none',
    justifyContent: 'space-evenly',
    alignContent: 'stretch',
  }
});

function StyledDialog(props) {
    const classes = useStyles();

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth={props.fullWidth} maxWidth={props.maxWidth}>
          <DialogContent>
            <div style={{ overflow: "hidden", height: "100%", width: "100%" }}>
                <Grid container className={classes.root}>
                  {props.children}
                </Grid>
            </div>
          </DialogContent>
        </Dialog>
    );
}

export default StyledDialog