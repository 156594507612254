import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import StyledButton from './StyledButton';


function Banner (props) {
    const [redirectPrimaryCallToAction, setRedirectPrimaryCallToAction] = useState();

    const useStyle = makeStyles({
        banner: {
            minHeight: '30vh',
            background: `linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ),  url(${props.bannerImage}) no-repeat center`,
            backgroundSize: 'cover',
            borderRadius: '10px',
            padding: '10rem 0',
            paddingLeft: '2.5rem',
            tintColor: 'rgba(255,0,0,5000)'
        }
    })
    
    const classes = useStyle();

    if (redirectPrimaryCallToAction) {
        return <Redirect push to={{ pathname: "/event/sos-lacrosse-camp-6" }}/>;
    }

    return (
        <Grid className={classes.banner} item container xs={12} alignItems="left">
            <Grid container item xs={6} direction="row">
                <Typography variant='h2' style={{color: '#fff', marginTop: '0.5rem', textAlign: 'left'}}>{props.title || "First line"}</Typography>
            </Grid>
            <Grid container item xs={12} direction="row">
                <Typography variant='h5' style={{color: '#fff', marginTop: '0.5rem'}}>{props.subtitle || ""}</Typography>
            </Grid>
            <Grid container item direction="row">
                <StyledButton variant='light' style={{margin: '0.5rem 0 0 0'}} onClick={() => setRedirectPrimaryCallToAction(true)}>{props.linkText}</StyledButton>
            </Grid>
        </Grid>
    )
}


export default Banner;
