import React, {useState} from "react";
import { useStripe, useElements, Elements, CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StyledButton from '../components/StyledButton';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography'

const stripe_promise = loadStripe('pk_live_51IUeoYEc2zg5lwj2cc9ov5Avtf1LlzXX86fG2CKjAiO72ZYeuU1UJWoikLOpHeoMiUtMLOYf8KKV6rSxLLbGw0mW00avnoIJgg');

const card_styles = {
  base: {
    color: "#424770",
    fontFamily: "axia, Source Code Pro, monospace, Montserrat",
    "::placeholder": {
      color: "#aab7c4"
    }
  },
  invalid: {
    color: "#9e2146"
  }
};

function StripeCCFormInner(props) {
    const stripe = useStripe();
    const [ disabled, setDisabled ] = useState(false);
    const elements = useElements();
    const payment_intent_secret = props.secret;
    const handle_payment_success = props.onSuccess;
    
    const handle_submit = async () => {    
        setDisabled(true);         
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet
            return;
        }

        const payment_method = {
          type: "card",
          card: elements.getElement(CardNumberElement)
        };

        const payment_response = await stripe.confirmCardPayment(payment_intent_secret, {payment_method: payment_method});
    
        if (payment_response.error) {
          // TODO handle error
        } else {
          handle_payment_success();
        }        
    };

    return (
      <Grid xs={12} container style={{marginTop: 10, margin: 0}} justifyContent='space-around'>
        <Grid item xs={11} style={{border: '1px solid dimgray', borderRadius: '5px', margin: '5px', padding: '5px'}}>
          <Typography variant='caption' align="left">Card Number</Typography>
          <CardNumberElement options={{showIcon: true, style: card_styles}}/>
        </Grid>
        <Grid item xs={5} style={{border: '1px solid dimgray', borderRadius: '5px', margin: '5px', padding: '5px'}}>
          <Typography variant='caption' align="left">Security Code</Typography>
          <CardCvcElement options={{style: card_styles}}/>
        </Grid>
        <Grid item xs={5} style={{border: '1px solid dimgray',  borderRadius: '5px', margin: '5px', padding: '5px'}}>
          <Typography variant='caption' align="left">Expiry Date</Typography>
          <CardExpiryElement options={{style: card_styles}}/>
        </Grid>
        <Grid item xs={12}>
          <div style={{minHeight: '0.5rem'}} />
          <StyledButton onClick={() => handle_submit()} disabled={!stripe || disabled}>
            {!disabled ? "Pay" : "Submitting Payment" } </StyledButton>
        </Grid>
      </Grid>
    );
}

function StripeCCForm(props) {
  
  
  return (
    <Elements stripe={stripe_promise}>
      <StripeCCFormInner use_saved={props.use_saved} secret={props.secret} onSuccess={()=>props.onSuccess()}/>
    </Elements>
  );
}

export default StripeCCForm;