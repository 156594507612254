import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    palette: {
      primaryColor: '#3d694b',
      secondaryColor: '#bfd7cf',
      tertiaryColor: '#5d676f',
      darkColor: '#050503',
      // The off-white background black text combo
      primary: {
        light: '#fff',
        main: '#fffaf5',
        dark: '#d0d0d0',
        contrastText: '#1c1c1c'
      },
      // The inverse, off-white text and black background
      secondary: {
        light: '#2f2f2f',
        main: '#1c1c1c',
        dark: '#080808',
        contrastText: '#fffaf5',
        contrastTextDark: '#aeaeae' /* Want two contrast text colors */
      }
    },
    typography: {
      h1: {
        fontSize: '4rem',
        letterSpacing: '-0.04rem',
        fontWeight: 400,
        textDecoration: 'none',
        color: 'black',
        fontFamily: 'Arial, sans-serif',
      },
      h2: {
        fontSize: '3rem',
        letterSpacing: '-0.04rem',
        fontWeight: 'normal',
        textDecoration: 'none',
        color: '#1C1C1C',
        fontFamily: 'Arial, sans-serif',
      },
      h3: {
        fontSize: '2rem',
        fontWeight: 400,
        letterSpacing: '1px',
        textDecoration: 'none',
        color: 'black',
        fontFamily: 'Arial, sans-serif',
      },
      h4: {
        fontSize: '1.5rem',
        fontWeight: 400,
        textDecoration: 'none',
        color: 'black',
        fontFamily: 'Arial, sans-serif',
      },
      h5: {
        fontSize: '1.1rem',
        fontWeight: 400,
        textDecoration: 'none',
        color: 'black',
        fontFamily: 'Arial, sans-serif',
      },
      h6: {
        fontSize: '0.9rem',
        fontWeight: 600,
        textDecoration: 'none',
        color: 'black',
        fontFamily: 'Arial, sans-serif',
      },
      subtitle1: {
        fontSize: '0.8rem',
        fontWeight: 600,
        textDecoration: 'none',
        color: 'black',
        fontFamily: 'Arial, sans-serif',
      },
      subtitle2: {
        fontSize: '0.9rem',
        textDecoration: 'none',
        color: 'dimgray',
        fontFamily: 'Arial Narrow, sans-serif',
      },
      body1: {
        fontSize: '1.1rem',
        fontWeight: 400,
        textDecoration: 'none',
        color: 'black',
        fontFamily: 'Arial, sans-serif',
      },
      body2: {
        fontSize: '1rem',
        fontWeight: 400,
        textDecoration: 'none',
        color: 'black',
        fontFamily: 'Arial, sans-serif',
      },
      caption: {
        fontSize: '0.9rem',
        textDecoration: 'none',
        color: 'dimgray',
        fontFamily: 'Arial, sans-serif',
      },
      overline: {
  
      }
    }
  });

export default theme;