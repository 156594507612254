import React, { useState, useEffect } from 'react';
import StripeCCForm from '../components/StripeCCForm'
import StyledButton from '../components/StyledButton';
import { Grid, Card, Link } from '@material-ui/core';
import pmp_axios from '../utils/pmp_axios'
import Typography from '@material-ui/core/Typography'
import * as dayjs from 'dayjs'
import image from '../assets/fillerIMG.png';


function Payment(props) {
    let registration = props.location.state.registration;    
    let invoiceId = registration.invoiceId;
    let lastFour = registration.stripeCCLastFour;    
    const [ paymentIntentSecret, setPaymentIntentSecret ] = useState();
    const [ hasCardSaved, setHasCardSaved ] = useState(lastFour);
    const [ receiptUrl, setReceiptUrl ] = useState();
    const [ finalizeSuccess, setFinalizeSuccess ] = useState();
    const [ showConfirmation, setShowConfirmation ] = useState(false);

    /* begin API functions */
    // result is success
    const post_pay_with_saved_card = async () => {
        const invoiceObj = { invoiceId: invoiceId };
        return await pmp_axios.post('/payment/payWithCardOnFile', invoiceObj);
    };

    // result is payment intent secret needed to load Stripe payment form
    const post_create_payment_intent = async () => {
        const invoiceObj = { invoiceId: invoiceId };
        return await pmp_axios.post('/Payment/createPaymentIntent', invoiceObj, (res)=>res.data.data[0].paymentIntentSecret);
    };

    // result is success
    const post_finalize_payment = async () => {
        const invoiceObj = { invoiceId: invoiceId };
        return await pmp_axios.post('/payment/finalizePayment', invoiceObj, (res)=>res.data.data.receiptUrl);
    };
    /* end API functions */

    /* begin helper functions */
    const create_and_set_intent_secret = async () => {
        const intent_res = await post_create_payment_intent();
        if (intent_res.success) {
            setPaymentIntentSecret(intent_res.parsed_result);
        } else {
            // TODO handle error
        }
    }
    /* end helper functions */

    /* begin event functions */
    const handle_payment_success = async () => {
        const finalize_res = await post_finalize_payment();
        if (finalize_res.success) {            
            setFinalizeSuccess(true);
            setShowConfirmation(true);
            setReceiptUrl(finalize_res.parsed_result);
            
        } else {
            // TODO notify admin of error
            setFinalizeSuccess(true);
            setShowConfirmation(true);
            console.log('Error finalizing payment');
        }
    }

    const pay_with_card_and_confirm = async () => {
        const pay_res = await post_pay_with_saved_card();
        if (pay_res.success) {
            const finalize_res = await post_finalize_payment();
            if (finalize_res.success) {                
                setFinalizeSuccess(true);
                setShowConfirmation(true);
                setReceiptUrl(finalize_res.parsed_result);            
            } else {
                // TODO handle error
                setFinalizeSuccess(false);                
            }
        } else {
            // TODO handle error
            setFinalizeSuccess(false);            
        }
    }
    /* begin event functions */

    /* begin render functions */
    function QuickPay(props) {
        return (
            <Grid container direction="row" spacing={4} align="center" justify="center">
                <Grid item xs={12}>
                    {/* TODO add credit card clipart here */}
                    <img style={{border: '2px solid black', maxWidth: '100%'}} alt="placeholder" src={image}/> 
                </Grid>
                <Grid container item xs={12} style={{justifyContent: "center", alignItems: "center"}}>
                    <Typography variant='body1' align="center">
                        We have a card ending in {lastFour} on file... would you like to use that?
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <StyledButton onClick={() => pay_with_card_and_confirm()}>Confirm Payment</StyledButton>
                </Grid>
                <Grid item xs={12}>
                    <StyledButton onClick={() => setHasCardSaved(false)}>Enter New Card</StyledButton>
                </Grid>
            </Grid>
        );
    }

    function CollectPayment(props) {
        
        useEffect(() => {
            if (!paymentIntentSecret) {
                create_and_set_intent_secret();
            }
        }, []);
        
        if (paymentIntentSecret) {
            return (
                <Grid container alignItems="center">
                    <Grid item xs={12}>
                        <StripeCCForm secret={paymentIntentSecret} onSuccess={()=>handle_payment_success()}/>
                    </Grid>
                </Grid>
            );
        } else {
            return (                
                <PaymentLoading/>
            );
        }
    }

    function PaymentLoading(props) {
        return (
            <Grid container>
                <Grid item xs={12}>
                <Typography variant='body1' align="center">Payment Form Loading...</Typography>
                </Grid>
            </Grid>
        );
    }

    function RegistrationSummary({registration}) {
        return (
            
            <Grid container direction="row" spacing={2} alignItems="stretch" justify="space-between">                
                <Grid item xs={12} >
                        <Typography variant='h3' align="center">Registration Summary</Typography>
                </Grid>

                <Grid item xs={12} container direction="row">
                    <Grid item xs={12} >
                        <Typography variant='body1'>{registration.event.name}</Typography>
                    </Grid>
                </Grid>

                <Grid item xs={12} container direction="row" spacing={0}>
                    <Grid item xs={12} >
                        <Typography variant='body1'>Location</Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <Typography variant='body2'>{registration.event.location.name}</Typography>
                    </Grid>
                </Grid>

                <Grid item xs={12} container direction="row">
                    <Grid item xs={12} >
                        <Typography variant='body1'>Time</Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <Typography variant='body2'>
                            {dayjs(registration.event.startDate).format('ddd, MMM D, h:mm A')}
                            {" - "}
                            {dayjs(registration.event.endDate).format('h:mm A')}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item xs={12} container direction="row">
                    <Grid item xs={12} >
                        <Typography variant='body1'>Players</Typography>
                    </Grid>
                    <Grid item xs={12} >
                        {registration.players.map((player) => <Typography variant='body2'>{player.first}</Typography>)}
                    </Grid>
                </Grid>

                <Grid item xs={12} container direction="row">
                    <Grid item xs={12} >
                        <Typography variant='body1'>Total</Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <Typography variant='body2'>${registration.invoiceAmount}.00</Typography>
                    </Grid>
                </Grid>
            </Grid>
            
        );
    }

    function RegistrationSuccess(props){
        return (
            <Grid container direction="row" spacing={5} alignItems="stretch" justify="space-evenly">
                <Grid item xs={12}>
                    <Typography variant='h2'>Registration Success!</Typography>
                </Grid>
                <Link href={receiptUrl} variant='body1' target="_blank" color='inherit' style={{marginBottom: 20, textDecoration: 'underline'}}>{receiptUrl ? 'Payment Receipt' : 'Contact an admin for payment receipt'}</Link>              
                <RegistrationSummary registration={registration}/>
            </Grid>
        );
    }

    function RegistrationFailure(props){
        return (
            <Grid container direction="row" spacing={10} alignItems="stretch" justify="space-evenly">
                <Grid item xs={12}>
                    <Typography variant='h2'>Registration Failed</Typography>
                </Grid>
                <Grid xs={12}>
                    <Typography variant='body1'>Unfortunately we failed to finalize your registration.  Please try again or contact an administrator if you have any additional issues.</Typography>
                </Grid>
            </Grid>
        );
    }

    /* end render functions */

    if (showConfirmation) {
        if(finalizeSuccess){
            return <RegistrationSuccess />
        } else {
            return <RegistrationFailure />
        }            
    } else {
        return (
            <Grid container direction="row" spacing={10} alignItems="stretch" justify="space-evenly">
                
                <Grid container item md={4} xs={10}>
                    <Card style={{padding: 15}}>
                        <RegistrationSummary registration={registration}/>
                    </Card>
                </Grid>

                <Grid container item md={4} xs={10}>
                    <Card style={{padding: 15}}>
                        {hasCardSaved ? <QuickPay/> : <CollectPayment/> }
                    </Card>
                </Grid>

            </Grid>
        );
    } 
}

export default Payment;