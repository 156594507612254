import { TextField } from '@material-ui/core';

function StyledInput(props) {
    
    return (
        <TextField
            fullWidth={true}
            variant="outlined"
            {...props}
            onChange={(e) => props.onChange(e.target.value)}
            onBlur={(e) => props.onBlur(e.target.value)}
        />
    );
}

export default StyledInput;