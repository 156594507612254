import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, Typography, Link, CircularProgress, Divider, useTheme } from '@material-ui/core';
import AppContext from '../context';
import StyledButton from '../components/StyledButton';
import StyledInput from '../components/StyledInput';
import pmp_axios from '../utils/pmp_axios'
import { Redirect } from 'react-router-dom';
import logo from '../assets/pmpLogo.png';

function AuthPage(props) {
  const context = useContext(AppContext);
  const [toggle, setToggle] = useState(false);
  const [formInfo, setFormInfo] = useState({});
  const [signInEmail, setSignInEmail] = useState('');
  const [signInPassword, setSignInPassword] = useState('');
  const [signInErrorText, setSignInErrorText] = useState();
  const [signUpErrorText, setSignUpErrorText] = useState();
  const [errorFields, setErrorField] = useState({
    email: null,
    guardianFirst: null,
    guardianLast: null,
    playerFirst: null,
    playerLast: null,
    password: null,
    confirmPassword: null,
  });
  const [signInFieldsFilled, setSignInFieldsFilled] = useState(false);
  const [signUpFieldsFilled, setSignUpFieldsFilled] = useState(false);
  const [signInLoading, setSignInLoading] = useState(false);
  const [signUpLoading, setSignUpLoading] = useState(false);
  const theme = useTheme();

  useEffect(()=>{
    if(signInEmail.length && signInPassword.length) setSignInFieldsFilled(true)
    else setSignInFieldsFilled(false);
  },[signInEmail, signInPassword])

  useEffect(()=>{
    const formFieldsFilled = formInfo.email && formInfo.password && formInfo.confirmPassword && formInfo.guardianFirst && formInfo.guardianLast && formInfo.playerFirst && formInfo.playerLast;
    if(formFieldsFilled) setSignUpFieldsFilled(true);
    else setSignUpFieldsFilled(false);    
  },[formInfo])

  /* begin API functions */
  const post_guardian = async () => {
    const guardianObj = {
      first: formInfo.guardianFirst,
      last: formInfo.guardianLast,
      email: formInfo.email,
      password: formInfo.password,
    };
    return await pmp_axios.post('/user/guardian', guardianObj, (res)=>res.data.user);
  }

  const post_player = async () => {
    const playerObj = {
      first: formInfo.playerFirst,
      last: formInfo.playerLast,
      email: formInfo.email, // TODO allow players to have their own email
    };
    return await pmp_axios.post('/user/player', playerObj, (res)=>res.data.player);
  }

  const post_signin = async () => {
    const signInObj = {
      email: signInEmail,
      password: signInPassword 
    };
    return await pmp_axios.post('/user/signIn', signInObj, (res)=>res.data.user);
  };

  const get_profile = async () => {
    return await pmp_axios.get('/user/profile', (res)=>res.data.profile);
  };
  /* end API functions */

  /* begin helper functions */
  const update_form_info = (field, value) => {
    setErrorOnField(field, false);
    setFormInfo({...formInfo, [field]: value});
  }

  const setErrorOnField = (field, value = true) => {
    setErrorField({...errorFields, [field]: value});
  }

  const signin = async () => {
    const signin_res = await post_signin();
    if (signin_res.success) {
      localStorage.setItem("accessToken", signin_res.parsed_result.accessToken);
      localStorage.setItem("refreshToken", signin_res.parsed_result.refreshToken);
      const profile_res = await get_profile();
      if (profile_res.success) {
        context.setUser(profile_res.parsed_result);
        return 'success';
      } else {
        return 'Failed to get user info, please contact support.'
      }
    } else {
      // commenting this out b/c not best security practice to tell user if username is correct
      // return signin_res.error; 
      return 'Provided email/password combination is incorrect.'
    }
  }

  const signup = async () => {
    const guardian_res = await post_guardian();
    if (guardian_res.success) {
      localStorage.setItem("accessToken", guardian_res.parsed_result.accessToken);
      localStorage.setItem("refreshToken", guardian_res.parsed_result.refreshToken);
    } else {
      return guardian_res.error;
    }

    const player_res = await post_player();
    if (player_res.success) {
      // be happy there was no error
    } else {
      return player_res.error;
    }

    const profile_res = await get_profile();
    if (profile_res.success) {
      context.setUser(profile_res.parsed_result);
      return 'success';
    } else {
      return 'Failed to get user info, please contact support.';
    }
  };

  const validate_signup = () => {
    var {email, password, confirmPassword, guardianFirst, guardianLast, playerFirst, playerLast} = formInfo;
    const emailRe = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email || !emailRe.test(email.toLowerCase())) {
      setErrorOnField('email');
      return 'Invalid Email';
    }

    // const passwordRe = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    // if (!passwordRe.test(password)) {
    //   setErrorOnField('password');
    //   return 'Invalid Password: Must contain upper, lower, digit, special character, and at least six characters';
    // }

    if (password !== confirmPassword) {
      setErrorOnField('confirmPassword');
      return 'Passwords Do Not Match';
    }

    if (!guardianFirst || guardianFirst === '') {
      setErrorOnField('guardianFirst');
      return 'Invalid Guardian First Name';
    }
    if (!guardianLast || guardianLast === '') {
      setErrorOnField('guardianLast');
      return 'Invalid Guardian Last Name';
    }
    if (!playerFirst || playerFirst === '') {
      setErrorOnField('playerFirst');
      return 'Invalid Player First Name';
    }
    if (!playerLast || playerLast === '') {
      setErrorOnField('playerLast');
      return 'Invalid Player Last Name';
    }
    return 'valid';
  }
  /* end helper functions */

  /* begin event functions */
  const handle_signup = async () => {
    setSignUpLoading(true);
    const valid = validate_signup();
    if (valid === 'valid') {
      const signup_text = await signup();
      if (signup_text === 'success') {
        handle_close();
        setSignUpErrorText(null);
        setFormInfo({});
      } else {
        setSignUpErrorText(signup_text);
      }
    } else {
      setSignUpErrorText(valid);
    }
    setSignUpLoading(false);
  }

  const handle_signin = async () => {
    setSignInLoading(true);
    const signin_text = await signin();
    if (signin_text === 'success') {
      handle_close();
      setSignInErrorText(null);
    } else {
      setSignInErrorText(signin_text);
    }
    setSignInLoading(false);
  }

  const handle_close = () => {
    setFormInfo({});
    setToggle(false);
    setSignInEmail('');
    setSignInPassword('');
    setSignInErrorText(null);
    setSignUpErrorText(null);
    props.handleClose();
  }
  /* end event functions */

  /* begin rendering functions */
  function SignInSignUp() {
    const spacingNum = 1;
    return (
      <Box>
        <Grid container>
          <Grid style={{padding: '5rem 0'}} item xs={4} justify='center' align='center' flexItem>
            <Grid container item xs={10} spacing={3}>
              <Grid item xs={12}>
                <Typography variant="body1" align="left">Login to your account.</Typography>
              </Grid>
              <Grid item xs={12} style={{height: '3rem', paddingTop: 0, paddingBottom: 0}} alignItems='top'>
                <Typography variant='body2' align='left' style={{color: 'red'}}>{signInErrorText}</Typography>
              </Grid>
              {/* SignIn Inputs */}
              <Grid item xs={12} container spacing={1}>
                <Grid item xs={12}>
                  <StyledInput onChange={setSignInEmail} value={signInEmail} placeholder='Email'/>
                </Grid>
                <Grid item xs={12}>
                  <StyledInput onChange={setSignInPassword} value={signInPassword} type="password" placeholder='Password'/>
                </Grid>
              </Grid>
              {signInErrorText &&
                <Grid item>
                  <Typography variant='body2' style={{color: 'red'}}>{signInErrorText}</Typography>
                </Grid>
              }
              <Grid item xs={12} style={{height: '8rem', position: 'relative'}}>
                <Typography variant="body2" align="center" style={{verticalAlign: 'middle', position: 'absolute', top: '25%'}}>By signing in, you agree to PMP's <Link href="/privacy" target="_blank" style={{color: 'dimgray'}}>Privacy Policy</Link> and <Link href="/terms" target="_blank" style={{color: 'dimgray'}}>Terms of Use</Link>.</Typography>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={2}/>
                <Grid item xs={8}>
                  <StyledButton disabled={!signInFieldsFilled} fullWidth onClick={() => handle_signin()} style={{minHeight: 40}}>Sign In</StyledButton>
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={2}/>
                <Grid item xs={1}>
                  <CircularProgress style={{marginTop: '-0.5rem', visibility: signInLoading ? 'visible' : 'hidden'}} color="" />
                </Grid>
                <Grid item xs={3}/>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={1} flexItem>
            <Divider orientation="vertical"></Divider>
          </Grid>
          <Grid style={{padding: '5rem 0'}} item xs={7} flexItem>
            <Grid container xs={12} justify='center' spacing={3}>
              <Grid container item xs={11} justify="space-evenly" spacing={spacingNum}>
              <Grid item xs={12}>
                <Typography variant="body1" align="left">Sign up for an account.</Typography>
              </Grid>
              <Grid item xs={12} style={{height: '3rem', paddingTop: 0, paddingBottom: 0}}>
                <Typography variant='body2' align='left' style={{color: 'red'}}>{signUpErrorText}</Typography>
              </Grid>
              {/* Guardian Info */}
                <Grid container item direction="row" spacing={spacingNum}>
                  <Grid item xs={6}>
                    <StyledInput error={errorFields.guardianFirst} onChange={v => update_form_info('guardianFirst', v)} value={formInfo.guardianFirst} placeholder='Guardian First'/>
                  </Grid>
                  <Grid item xs={6} >
                    <StyledInput  error={errorFields.email} onChange={v => update_form_info('email', v)} value={formInfo.email} placeholder='Guardian Email'/>
                  </Grid>
                </Grid>
                <Grid container item direction="row" spacing={spacingNum}>
                  <Grid item xs={6}>
                    <StyledInput  error={errorFields.guardianLast} onChange={v => update_form_info('guardianLast', v)} value={formInfo.guardianLast} placeholder='Guardian Last'/>
                  </Grid>
                  <Grid item xs={6}/>
                </Grid>
              </Grid>
              {/* Player Info & Password */}
              <Grid container item xs={11} justify="space-evenly" spacing={spacingNum}>
                <Grid container item direction="row" spacing={spacingNum}>
                  <Grid item xs={6}>
                    <StyledInput error={errorFields.playerFirst} onChange={v => update_form_info('playerFirst', v)} value={formInfo.playerFirst} placeholder='Player First'/>
                  </Grid>
                  <Grid item xs={6}>
                    <StyledInput  error={errorFields.password} onChange={v => update_form_info('password', v)} type="password" value={formInfo.password} placeholder='Password'/>
                  </Grid>
                </Grid>
                <Grid container item direction="row" spacing={spacingNum}>
                  <Grid item xs={6}>
                    <StyledInput  error={errorFields.playerLast} onChange={v => update_form_info('playerLast', v)} value={formInfo.playerLast} placeholder='Player Last'/>
                  </Grid>
                  <Grid item xs={6}>
                    <StyledInput  error={errorFields.confirmPassword} onChange={v => update_form_info('confirmPassword', v)} type="password" value={formInfo.confirmPassword} placeholder='Confirm Password'/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={12} justify="center">
                {/* Confirmation Button and spinner */}
                <Grid container direction="row">
                  <Grid item xs={2}>
                    <CircularProgress style={{marginTop: '0rem', visibility: signUpLoading ? 'visible' : 'hidden'}} color="" />
                  </Grid>
                  <Grid item xs={8}>
                    <StyledButton disabled={!signUpFieldsFilled} fullWidth onClick={() => handle_signup()} style={{height: 40, width: '100%'}}>Create Account</StyledButton>
                  </Grid>
                  <Grid item xs={2}/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      
    );
  }
  /* end rendering functions */

  if (context.user) {
    return <Redirect push to="/"/>
  } else return SignInSignUp();
}

export default AuthPage;
