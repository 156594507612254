import React, { useEffect, useState } from 'react';
import { Grid, Box, makeStyles } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import * as dayjs from 'dayjs';
import pmp_axios from '../utils/pmp_axios'
import StyledButton from '../components/StyledButton';
import EventsFilter from '../components/EventsFilter';
import ColoredTypography from '../components/ColoredTypography';
import Carousel from '../components/Carousel';
import Banner from '../components/Banner';


function Home(props) {
    const { domain } = props;
    const [filters, setFilters] = useState();
    const [redirectPrimaryCallToAction, setRedirectPrimaryCallToAction] = useState();
    const [events, setEvents] = useState([]);
    const [trainers, setTrainers] = useState([]);     

    // The background image for the 'home'
    const useStyles = makeStyles( theme => ({
        root: {                
                [theme.breakpoints.up("md")]: {
                    backgroundPositionY: 0,
                    background: `linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url(${domain.homeMainBanner}) no-repeat center center fixed`, 
                    backgroundSize: 'cover',
                    // objectFit:'fill',
                    minHeight: 800,
                  },
                
                [theme.breakpoints.down("sm")]: {
                    backgroundSize:'cover',
                    backgroundImage: `url(${domain.homeMainBanner})`, 
                    backgroundRepeat: 'no-repeat',
                    width: '100vw',
                    height: '100vh',                   
                  },
        }
    }));

    /* Begin API functinos */
         
    const get_and_set_trainers = async () => {
        const fetchedTrainers = await pmp_axios.get('/trainer', (res)=>res.data.profiles);
        setTrainers(fetchedTrainers.parsed_result);
    }

    const get_and_set_events = async () => {
        const playerObj = {
            startDate: dayjs().toDate(),
            endDate: dayjs().add(6, 'month').toDate(),
            skills: [],
            ages: [],
            locations: [] // TODO add
        };
        const fetchedEvents = await pmp_axios.post('/event/list', playerObj, (res)=>res.data.events);
        setEvents(fetchedEvents.parsed_result);
    }
   
    /* End API functinos */
    
    useEffect(()=>{
        get_and_set_events();
        get_and_set_trainers();
    },[]);

    function HomeBody() {
        const classes = useStyles();
        return (
            <Box className={classes.root}>
                <Grid container direction="row" justify="space-around" alignItems="center" style={{minHeight: '80vh'}}>
                    <Grid item container direction="row" alignItems="center" justify="center" style={{height: '20%'}}> 
                        <Grid item xs={6}><EventsFilter onSubmission={(filters) => setFilters(filters)} updateMode='total'/></Grid>
                    </Grid>
                    <Grid item container direction="row" alignItems="center" justify="space-around" style={{height: '40%'}}>
                        <Grid item xs={12}><ColoredTypography variant='h1' color="primary.main" align="center">{domain.mainBannerPrimaryHeader}</ColoredTypography></Grid>
                        <Grid item xs={12} sm={8} md={6}><ColoredTypography variant='h4' color="primary.main">{domain.mainBannerPrimaryTagLine}</ColoredTypography></Grid>
                        <Grid item xs={12}><StyledButton style={{marginTop: '0.5rem'}} variant="light" size="large" onClick={() => setRedirectPrimaryCallToAction(true)}>{domain.mainBannerPrimaryButtonLabel}</StyledButton></Grid>
                    </Grid>
                </Grid>
                {
                    domain.host === 'sos' 
                    ?
                    <Grid container direction="row" justify="space-around" alignItems="center">
                        { trainers?.length ? <Carousel data={trainers} carouselTitle="Camp Staff" slideType="trainer"/> : null }
                    </Grid>
                    :
                    <Grid container direction="row" justify="space-around" alignItems="center">
                        <Carousel data={events} carouselTitle="Upcoming Clinics" slideType="event" />
                        <Carousel data={trainers} carouselTitle="Camp Staff" slideType="trainer"/>
                    </Grid>  
                }
                <Grid container direction="row" justify="space-around" style={{padding: '1rem', backgroundColor: '#fffaf5'}}>
                    <Banner title={domain.bottomBannerTitle} bannerImage={domain.bottomBannerImage} linkText={domain.bottomBannerLinkText}/>
                </Grid>
            </Box>
        );
    }

    if (filters) {
        return <Redirect push to={{ pathname: "/upcoming", state: {filters: filters} }}/>;
    } else if (redirectPrimaryCallToAction) {
        return <Redirect push to={{ pathname: "/event/sos-lacrosse-camp-6" }}/>;
    } else {
        return <HomeBody/>;
    }
}

export default Home;
