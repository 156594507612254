import React, { useState, useContext, useEffect } from 'react';
import { Box, Grid, List, ListItem, Typography, Hidden, TextField, Card, FormGroup, FormControlLabel, makeStyles, useTheme } from '@material-ui/core';
import { Alert, ToggleButton } from '@material-ui/lab';
import Image from 'material-ui-image';
import { Redirect } from 'react-router-dom';
import StyledButton from '../components/StyledButton';
import StyledModal from '../components/StyledModal';
import PaymentModal from '../components/PaymentModal';
import AuthButton from '../components/AuthButton';
import Content from '../components/Content';
import AppContext from '../context';
import { FaChevronLeft } from 'react-icons/fa'
import pmp_axios from '../utils/pmp_axios';
import logo from '../assets/pmpLogo.png';
import check from '../assets/check.svg';
import SosPlayerPage from '../components/SosPlayerPage';

const samplePhotoUrl = 'https://images.unsplash.com/photo-1604336732494-d8386c7029e3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDZ8fHxlbnwwfHx8fA%3D%3D&w=1000&q=80';

function Event(props) {
    const [upcoming, goToUpcoming] = useState(false);
    const [ageString, setAgeString] = useState();
    const [skillString, setSkillString] = useState();
    const [event, setEvent] = useState(props.location?.state?.event); // from redirect
    const context = useContext(AppContext);
    const domain = context.domain;
    const { history } = props;
    const theme = useTheme();

    const useStyles = makeStyles({
        trainer: {
            width: '100%',
            minHeight: '2rem',
            border: '1px black solid',
            borderRadius: '10px',
            backgroundColor: 'rgba(0,0,0,0)',
            margin: 10, 
            padding: 10,
            display: 'inline-block',
        },
        trainerText: {
            paddingLeft: '0.4rem'
        },
        chevronButton: {
            backgroundColor: 'rgba(0,0,0,0)',
            border: '#000 1px solid',
            borderRadius: '50%',
            height: '2.5em',
            width: '2.5em',
            marginRight: '0.5em',
            cursor: 'pointer',
        },
        backButton: {
            position: 'absolute',
            top: '1rem',
            left: '1rem',
        },
        skillPill: {
            // border: `1px solid #000`, 
            // borderRadius: 5, 
            verticalAlign: 'middle',
            padding: '0.5rem',
            marginBottom:'0.5rem',
            color: '#AEAEAE',
            fontSize: '1rem',
            fontFamily: 'Arial Narrow',
        },
    });

    const classes = useStyles();
    
    useEffect(()=>{
            const pathname = window.location.pathname;
            const pathitems = pathname.split('-');
            const event_id = pathitems[pathitems.length-1];
        // direct link
        if ((!event && setEvent) || event?.id != event_id) {
            set_event(event_id);
        } else {
            // this section probably isn't necessary anymore
            // will remove once tests are written
            const eventName = event.name.toLowerCase().replaceAll(' ', '-');
            const pathname = eventName + '-' + event.id;
            history.push(`/event/${pathname}`)
        }
    },[]);

    useEffect(()=>{
        // turn list of ages into string
        if(event?.ages.length) {
            let string = '';
            event.ages.forEach((age) => {
                string += age.name + ', '
            })
            string = string.slice(0, string.length - 2);
            setAgeString(string.toUpperCase());
        }

        // turn list of skills into string
        if(event?.skills.length) {
            let string = '';
            event.skills.forEach((skill) => {
                string += skill.name + ', '
            })
            string = string.slice(0, string.length - 2);
            setSkillString(string.toUpperCase());
        }
    },[event]);
    
    /* begin API functions */
    const get_event = async (id) => {
        return await pmp_axios.get('/event/'+id, (res)=>res.data.event);
    }
    /* end API functions */

    /* begin helper functions */
    const get_names = (items) => {
        const names = items.reduce((acc, item, index)=>{
            if (index > 0) acc += ', ';
            return acc += item.name
        }, '')
        return names
    }

    const set_event = async (id) => {
        // this may be quicker if we just pick from events already 
        // pulled on previous page that were in the carousel (TODO after MVP).
        // but also have an if-check in case they were linked directly to this page.
        const event_res = await get_event(id);
        if (event_res.success) {
            setEvent(event_res.parsed_result);
        } else {
            // TODO handle error
        }
    }

    const has_empty_field = (array) => {
        let there_exists_empty = false;
        array.forEach(element => {
            for (const property in element) {
                if (element[property] === null || element[property] === "") {
                    there_exists_empty = true;
                }
            }
        });
        return there_exists_empty;
    }
    /* end helper functions */

    /* begin rendering functions */
    function RegisterButton() {
        const [ showPayment, setShowPayment ] = useState(false);
        const [ registration, setRegistration ] = useState();
        const [ open, setOpen ] = useState(false);
        const [ readyForRegistration, setReadyForRegistration] = useState(false);
        var players = context.user.players;
        const [ redirectToGoalie, setRedirectToGoalie] = useState(false);
        const [ selectedPlayers, setSelectedPlayers ] = useState([]);
        const [ selectedPlayersInfoNeeded, setSelectedPlayersInfoNeeded ] = useState();

        const handle_player_select = (event,value, selected) => {
            const id = parseInt(value);
            const newlySelectedPlayers = selectedPlayers.map((p)=>p);
            selected ? newlySelectedPlayers.splice(newlySelectedPlayers.indexOf(id), 1) : newlySelectedPlayers.push(id);
            setSelectedPlayers(newlySelectedPlayers);
        };
    
        // returns the registration created
        const post_register = async () => {
            const registerObj = {
                "playerIds": selectedPlayers,
                "eventId": event.id
            };
            const result = await pmp_axios.post('/event/registration', registerObj, (res)=>res.data.registration);
            return result;
        }       

        const handle_register = async () => {
            selectedPlayers.forEach(playerId => {
                var checkPlayer = players.find(p => p.id === playerId);
                if(!checkPlayer.additionalFieldsCompleted && event.requireAdditionalProfileFields){                    
                    setSelectedPlayersInfoNeeded(checkPlayer);
                    setReadyForRegistration(true);
                }else{
                    setReadyForRegistration(true);
                    prepare_for_payment();
                }
            });         
        }

        const handle_post_additional_field_entry = async () => {
            var profile_res = await pmp_axios.get('/user/profile', (res)=>res.data.profile);
            if (profile_res.success)
                players = profile_res.parsed_result.players;
            handle_register();
        }

        const prepare_for_payment = async () => {
            let res = await post_register();

            const eventRegistration = res.parsed_result; 
            
            if (res.success) {
                setRegistration(eventRegistration); // should be res.parsed_result once the endpoint works
                setShowPayment(true);
            } else {
                // TODO handle error
            }
        }

        // const redirect_to_goalie = async () => {
        //     window.location.href = '/event/sos-lacrosse-camp-5';
        // }
        const redirect_to_goalie = async () => {
            // set_event("2");
            // setOpen(true);
            window.location.href = '/event/sos-lacrosse-camp-5';
        }

        if(redirectToGoalie){
            return ( <Redirect push to={{ pathname: '/event/sos-lacrosse-camp-5', state: {data: history} }}/>);
        }else{        
            return (
                <div>
                    { (event.id !== 4) ?
                        <Grid container>
                            <Grid item style={{marginRight:"5px"}}>
                            {/*<StyledButton onClick={() => setOpen(true)}>PLAYER REGISTRATION</StyledButton>*/}
                            </Grid>
                            <Grid item>                        
                                {/*<StyledButton onClick={() => redirect_to_goalie()}>GOALIE REGISTERATION</StyledButton>*/}
                            </Grid> 
                            {<Typography variant='body2' align='center'>Registration for this event has closed.  Please reach out to Mary at 513-967-6697 if you have any additional questions or would like attend camp.  Thanks!</Typography>}
                        </Grid>
                        : 
                        <Grid item style={{marginRight:"5px"}}>
                            {/*<StyledButton onClick={() => setOpen(true)}>GOALIE REGISTRATION</StyledButton> */}
                            {<Typography variant='body2' align='center'>Registration for this event has closed.  Please reach out to Mary at 513-967-6697 if you have any additional questions or would like attend camp.  Thanks!</Typography>}
                        </Grid>
                    }
                    {
                        readyForRegistration
                        ?
                            !showPayment ? 
                            <SosPlayerPage open={open} handleClose={() => handle_post_additional_field_entry()} setOpen={setOpen} event={event} player={selectedPlayersInfoNeeded} {...props} />
                            : 
                            <PaymentModal style={{marginTop: 0}} open={open} onClose={() => setOpen(false)} setShowPayment={setShowPayment} setOpen={setOpen} state={registration} {...props}  />
                        :
                        <StyledModal open={open} onClose={() => setOpen(false)}>
                            <Grid container justifyContent='center' alignContent='space-around' alignItems='center' style={{height: '100%'}}>
                                <Grid item>
                                    <img src={logo} alt="logo" style={{height: '40px'}}/>
                                </Grid>
                                <Grid item xs={12} m={2}>
                                    <Typography variant='body1' align='center'>Select your players that you'd like to register (You can always add more from your profile page).</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormGroup style={{alignItems: 'center'}}>
                                        <Grid container item xs={8} justifyContent='center' alignItems='center'>
                                            {players.map((player) => {return (
                                                <Grid key={player.id} item xs={12/players.length}>
                                                    <FormControlLabel control={
                                                        <ToggleButton
                                                            onChange={(e) => handle_player_select(e,player.id, selectedPlayers.some(id => id === player.id))}
                                                            name={player.id}
                                                            value={player.id}
                                                            selected={selectedPlayers.some(id => id === player.id)}
                                                            >{player.first}</ToggleButton>
                                                    }/>
                                                </Grid>
                                            );})}
                                        </Grid>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={8}>
                                    <StyledButton style={{width: '60%', margin:"auto"}} onClick={() => handle_register()} disabled={selectedPlayers.length === 0}>Register</StyledButton>                                   
                                </Grid>
                                <Grid item xs={12}/>
                            </Grid>
                        </StyledModal>
                    }
                </div>
            );
        }
    }

    function InviteButton() {
        const EMPTY_INVITEE = { email: null };

        const [ open, setOpen ] = useState(false);
        const [ buttonText, setButtonText ] = useState('Send Invitation');
        const [ invitees, setInvitees ] = useState([{...EMPTY_INVITEE}]);
        const [ inviteSent, setInviteSent ] = useState(false);
    
        const post_invite = async () => {
            const inviteObj = {
                "eventId": event.id,
                "invitees": invitees
            };
            return await pmp_axios.post('/event/invite', inviteObj);
        }

        const handle_invite = async () => {
            if(!inviteSent) {
                const res = await post_invite();
                if (res.success) {
                    // TODO add more inidication of success
                    setButtonText('Return to Event');
                    setInviteSent(true);
                } else {
                    // TODO handle error
                }
            } else {
                // reset in case they want to send another
                setOpen(false);
                setInviteSent(false);
                setInvitees([{...EMPTY_INVITEE}]);
                setButtonText('Send Invitation');
            }
        }

        const handle_invitee_change = (event, index) => {
            let triplets = [...invitees];
            const fieldName = event.target.name;
            triplets[index][fieldName] = event.target.value;
            setInvitees(triplets);
        }

        const handle_invitee_add = () => {
            let triplets = [...invitees];
            // Spread and collect to add a shallow copy of the empty invitee object
            triplets.push({...EMPTY_INVITEE});
            setInvitees(triplets);
        }

        const handle_invitee_remove = (index) => {
            let triplets = [...invitees];
            triplets.splice(index, 1);
            setInvitees(triplets);
        }

        function InviteeRow({invitee, index}) {
            return (
                <Grid item xs={12} container direction="row" justify="center" key={`invitee_${index}`}>
                    <Grid item style={{width: '100%'}}>
                        <TextField style={{width: '100%'}} onChange={(e) => handle_invitee_change(e, index)} label="Email Address" name="email" value={invitee.email || ""}></TextField>
                    </Grid>
                </Grid>
            );
        }

        // Open the form and clear any existing invitee data
        function initialize_form() {
            setOpen(true);
            setInvitees([{...EMPTY_INVITEE}]);
        }

        return (
            <div>
                {/* <StyledButton style={{marginLeft: '0.1rem'}} onClick={() => initialize_form()}>INVITE</StyledButton> */}
                <StyledModal style={{width: '50%', backgroundColor: '#fffaf5'}} open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth="md">
                    <Grid container justify='center' style={{width: '60%', height: '60%', backgroundColor: '#fffaf5'}} direction="row">
                        <Grid item>
                            <img src={logo} alt="logo" style={{height: '40px'}}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body1' align='center'>{ inviteSent ? 'Invite sent!' : 'Invite player to an event.'}</Typography>
                        </Grid>
                        { 
                        inviteSent 
                        ?
                        <img src={check} alt="checkmark" style={{height: '80px'}} />
                        :
                        <Grid item container xs={12}>
                            {/* Add a row of input fields (email, firstname, lastname) for each invitee*/}
                            {/* Outdated, need to phase this out into not-a-separate component */}
                            {invitees.map((invitee, index) => {
                                // Calling the component via this syntax to avoid a defocus error
                                return InviteeRow({invitee, index});
                            })}
                        </Grid>
                        }
                        <Grid item xs={12}>
                            {/* Verify that all of the fields are filled out before allowing button to be pressed */}
                            <StyledButton style={{width: '100%'}} onClick={() => handle_invite()} disabled={has_empty_field(invitees)}>{buttonText}</StyledButton>
                        </Grid>
                        <Grid item xs={12}/>
                    </Grid>
                </StyledModal>
            </div>
        );
    }

    function Trainer(props) {
        const { trainer, lead } = props;

        return (
            <Card className={classes.trainer}>
                <Grid container>
                    <Grid item xs={5}>
                        <Image src={trainer.profilePictureLink} cover alt={trainer.first + ' ' + trainer.last} style={{height: '50%', borderRadius: '4px', maxWidth: '100%'}}/>
                    </Grid>
                    <Grid item xs={7} container direction="column" justify="flex-start" alignItems="flex-start" className={classes.trainerText}>
                        <Grid item>
                            <Typography variant='h5' style={{textAlign: 'left', fontSize: '1rem'}}>{trainer.first+' '+trainer.last}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='subtitle2' style={{textAlign: 'left', lineHeight: 0.6,  fontSize: '0.8rem'}}>{lead ? <span ><br/>Head Coach</span> : ''}</Typography>
                        </Grid>
                        {/* <Grid item>
                            <StyledButton variant='light-border' style={{ marginTop: '0.7rem', padding: '0.1rem', lineHeight: 1}}  ><span style={{fontSize: '0.5rem', padding: '0.5rem', margin: 0}}>See More</span></StyledButton>
                        </Grid> */}
                    </Grid>
                </Grid>
            </Card>
        );
    }
    /* end rendering functions */

    if (upcoming) {
        return <Redirect push to={{ pathname: "/upcoming"}} />
    } else if (event) // prevent rendering when event is undefined
    return (
        <Content>
            {/* <Box style={{minHeight: '10vh'}} justify='flex-start'>
                <Grid justify='flex-start' className={classes.backButton}>
                    <button style={{display: 'inline-block'}} className={classes.chevronButton} onClick={() => goToUpcoming(true)}>
                        <FaChevronLeft style={{margin: '2px 0 0 -1px'}} />
                    </button>
                    <Typography style={{display: 'inline-block'}}>Back to all clinics</Typography>
                </Grid>
            </Box> */}
            <Grid container justify='center'>
                <Grid container item xs={12}>
                    <Grid container item md={12}>
                        <Grid item xs={12} style={{margin: 'auto'}}>
                            <Typography style={{padding: '1rem'}} />
                        </Grid>
                        <Grid item xs={12} style={{margin: 'auto'}}>
                            <Typography variant='h1' style={{padding:'2.5%'}}>{event.name}</Typography>
                        </Grid>
                        <Grid item container spacing={1} style={{margin: 'auto'}} justify='center' direction="row">
                            {event.location.name.length > 0 ?
                                <Grid item>
                                    <Typography style={{display: 'inline'}} className={classes.skillPill}>LOCATION:{event.location.name.toUpperCase()}</Typography>
                                </Grid>
                                : ""}
                            {ageString?.length > 0 ?
                                <Grid item>
                                    <Typography style={{display: 'inline'}} className={classes.skillPill}>AGES:{ageString}</Typography>
                                </Grid>
                                : ""}
                            {skillString?.length > 0 ?
                                <Grid item>
                                    <Typography style={{display: 'inline'}} className={classes.skillPill}>SKILLS:{skillString}</Typography>
                                </Grid>
                                : ""}
                            
                        </Grid>
                    </Grid>
                    <Hidden mdDown><Grid item xs={3}/></Hidden>
                </Grid>
                <Grid container item xs={12}>
                    <Grid item xs={12} style={{margin: '3rem'}}>
                        <Typography variant='body1' align='center' dangerouslySetInnerHTML={{ __html: event.description}}></Typography>
                    </Grid>
                    <Grid container>
                    <Grid container>
                        <Grid item md={12} >
                            <Typography variant="h4" align="center">Camp Focuses</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>                    
                            <Grid item md={6}>                            
                                <List>
                                    <ListItem>Shooting</ListItem>
                                    <ListItem>Ground Balls</ListItem>
                                    <ListItem>Dodging</ListItem>
                                    <ListItem>Stick Skills</ListItem>
                                    <ListItem>Face-offs</ListItem>                        
                                    <ListItem>Defense (Team &amp; Individual)</ListItem>
                                </List>
                            </Grid>
                            <Grid item md={6}>
                                <List>
                                    <ListItem>Strategy &amp; Tactics</ListItem>
                                    <ListItem>Goalie Techniques &amp; Training</ListItem>
                                    <ListItem>Individual Position Training</ListItem>
                                    <ListItem>Box Lacrosse Techniques &amp; Strategy</ListItem>
                                    <ListItem>Offensive Strategy</ListItem>
                                    <ListItem>Clears &amp; Rides</ListItem>
                                    <ListItem>Man-Up &amp; Man-Down</ListItem>
                                </List>
                            </Grid>
                        </Grid>
                    </Grid>  
                    <Grid container item justify='center' style={{marginBottom: '3rem'}}>
                        <Grid item>
                            {context.user ?
                            <RegisterButton/>
                            :
                            <AuthButton variant='light-border' style={{margin: '0.2rem'}}>SIGN IN TO REGISTER</AuthButton>
                            }
                        </Grid>
                        <Grid item>
                            {context.user ?
                            <InviteButton/>
                            :
                            <AuthButton variant='light-border' style={{margin: '0.2rem'}}>SIGN IN TO INVITE</AuthButton>
                            }
                        </Grid>
                    </Grid>

                    {/* <Grid item xs={12}>
                        <Typography variant='h4'>Trainers</Typography>
                    </Grid> */}
                    {/* <Grid container item xs={12} justify='center' style={{margin: 15}}>
                        {event.eventTrainers.map((trainer, index) => {return (
                            <Grid item key={index}>
                                <Trainer style={{padding: '0.2rem'}} trainer={trainer} lead={trainer.trainerId === event.leadTrainer}/>
                            </Grid>
                        );})}
                    </Grid> */}
                </Grid>
            </Grid>
        </Content>
    )
    else return null
}

export default Event
