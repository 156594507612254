import React, { } from "react";
import { Card, Grid, Typography, makeStyles} from "@material-ui/core";
import Image from 'material-ui-image';
import StyledButton from '../components/StyledButton';

const samplePhotoUrl = 'https://images.unsplash.com/photo-1604336732494-d8386c7029e3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDZ8fHxlbnwwfHx8fA%3D%3D&w=1000&q=80';

export default function Trainer(props) {
    const { trainer, lead } = props;

    const useStyles = makeStyles({
        trainer: {
            width: '50%',
            minHeight: '2rem',
            border: '1px black solid',
            borderRadius: '10px',
            backgroundColor: 'rgba(0,0,0,0)',
            margin: 10, 
            padding: 10,
            display: 'inline-block',
        },
        trainerText: {
            paddingLeft: '0.4rem'
        },
    });
    
    const classes = useStyles();

    return (
        <Card className={classes.trainer}>
            <Grid container>
                <Grid item xs={5}>
                <Image disableTransition={true} src={trainer.profilePictureLink} cover alt={trainer.first + ' ' + trainer.last} style={{height: '50%', borderRadius: '4px', maxWidth: '100%'}}/>
                {/* <img src={samplePhotoUrl} cover alt={trainer.first + ' ' + trainer.last} style={{height: '50%', borderRadius: '4px', maxWidth: '100%'}}/> */}
                </Grid>
                <Grid item xs={7} container direction="column" justify="flex-start" alignItems="flex-start" className={classes.trainerText}>
                    <Grid item>
                        <Typography variant='h5' style={{textAlign: 'left', fontSize: '1rem'}}>{trainer.first+' '+trainer.last}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant='subtitle2' style={{textAlign: 'left', lineHeight: 0.6,  fontSize: '0.8rem'}}>{lead ? <span ><br/>Camp Director</span> : ''}</Typography>
                    </Grid>
                    <Grid item>
                        <StyledButton variant='light-border' style={{ marginTop: '0.7rem', padding: '0.1rem', lineHeight: 1}}><span style={{fontSize: '0.5rem', padding: '0.5rem', margin: 0}}>See More</span></StyledButton>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
}
