import React from 'react';

import { Typography } from '@material-ui/core';
import PaletteColorizer from '../utilities/PaletteColorizer';


function ColoredTypography(props) {
	return <PaletteColorizer component={<Typography></Typography>} colorProperty="color" {...props}></PaletteColorizer>
}

export default ColoredTypography;