import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

const datadog = () => {
    datadogRum.init({
        applicationId: '5df1f672-e5ad-459a-91d5-ae582de1bc04',
        clientToken: 'pub8f63b9fc5d918a0e2acb7dcc72c5655e',
        site: 'us5.datadoghq.com',
        service:'soslax',
        env: process.env.PMP_ENVIRONMENT,
        // Specify a version number to identify the deployed version of your application in Datadog 
        // version: '1.0.0',
        sessionSampleRate: 100,
        premiumSampleRate: 100,
        trackUserInteractions: true,
        defaultPrivacyLevel:'mask-user-input'
    });
    const env = process.env.PMP_ENVIRONMENT;
    datadogRum.startSessionReplayRecording();

    datadogLogs.init({
        clientToken: 'pub8f63b9fc5d918a0e2acb7dcc72c5655e',
        site: 'us5.datadoghq.com',
        forwardErrorsToLogs: true,
        sessionSampleRate: 100
    });
}

export default datadog