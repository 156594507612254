import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import datadog from './DataDog/index'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// to log results (for example: reportWebVitals(console.log))
reportWebVitals();

  //Initialize Datadog
  datadog();
