import React, { useState, useContext, useEffect, useRef } from 'react';
import { Box, Typography, Grid, MenuItem as SelectMenuItem, Link, TextField, FormControlLabel, Checkbox, useTheme, CircularProgress } from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';
import { Skeleton } from '@material-ui/lab';
import logo from './../assets/pmpLogo.png';
import * as dayjs from 'dayjs'
import AppContext from '../context';
import pmp_axios from '../utils/pmp_axios'
import StyledButton from '../components/StyledButton';
import StyledInput from '../components/StyledInput';
import NumberFormat from 'react-number-format';
import { Redirect } from 'react-router-dom';

import PersonIcon from '@material-ui/icons/Person';
import Event from '@material-ui/icons/Event';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountBalance from '@material-ui/icons/AccountBalance';

const states = [
    {
        "name": "Alabama",
        "abbreviation": "AL"
    },
    {
        "name": "Alaska",
        "abbreviation": "AK"
    },
    {
        "name": "Arizona",
        "abbreviation": "AZ"
    },
    {
        "name": "Arkansas",
        "abbreviation": "AR"
    },
    {
        "name": "California",
        "abbreviation": "CA"
    },
    {
        "name": "Colorado",
        "abbreviation": "CO"
    },
    {
        "name": "Connecticut",
        "abbreviation": "CT"
    },
    {
        "name": "Delaware",
        "abbreviation": "DE"
    },
    {
        "name": "District Of Columbia",
        "abbreviation": "DC"
    },
    {
        "name": "Florida",
        "abbreviation": "FL"
    },
    {
        "name": "Georgia",
        "abbreviation": "GA"
    },
    {
        "name": "Guam",
        "abbreviation": "GU"
    },
    {
        "name": "Hawaii",
        "abbreviation": "HI"
    },
    {
        "name": "Idaho",
        "abbreviation": "ID"
    },
    {
        "name": "Illinois",
        "abbreviation": "IL"
    },
    {
        "name": "Indiana",
        "abbreviation": "IN"
    },
    {
        "name": "Iowa",
        "abbreviation": "IA"
    },
    {
        "name": "Kansas",
        "abbreviation": "KS"
    },
    {
        "name": "Kentucky",
        "abbreviation": "KY"
    },
    {
        "name": "Louisiana",
        "abbreviation": "LA"
    },
    {
        "name": "Maine",
        "abbreviation": "ME"
    },
    {
        "name": "Maryland",
        "abbreviation": "MD"
    },
    {
        "name": "Massachusetts",
        "abbreviation": "MA"
    },
    {
        "name": "Michigan",
        "abbreviation": "MI"
    },
    {
        "name": "Minnesota",
        "abbreviation": "MN"
    },
    {
        "name": "Mississippi",
        "abbreviation": "MS"
    },
    {
        "name": "Missouri",
        "abbreviation": "MO"
    },
    {
        "name": "Montana",
        "abbreviation": "MT"
    },
    {
        "name": "Nebraska",
        "abbreviation": "NE"
    },
    {
        "name": "Nevada",
        "abbreviation": "NV"
    },
    {
        "name": "New Hampshire",
        "abbreviation": "NH"
    },
    {
        "name": "New Jersey",
        "abbreviation": "NJ"
    },
    {
        "name": "New Mexico",
        "abbreviation": "NM"
    },
    {
        "name": "New York",
        "abbreviation": "NY"
    },
    {
        "name": "North Carolina",
        "abbreviation": "NC"
    },
    {
        "name": "North Dakota",
        "abbreviation": "ND"
    },
    {
        "name": "Ohio",
        "abbreviation": "OH"
    },
    {
        "name": "Oklahoma",
        "abbreviation": "OK"
    },
    {
        "name": "Oregon",
        "abbreviation": "OR"
    },
    {
        "name": "Palau",
        "abbreviation": "PW"
    },
    {
        "name": "Pennsylvania",
        "abbreviation": "PA"
    },
    {
        "name": "Puerto Rico",
        "abbreviation": "PR"
    },
    {
        "name": "Rhode Island",
        "abbreviation": "RI"
    },
    {
        "name": "South Carolina",
        "abbreviation": "SC"
    },
    {
        "name": "South Dakota",
        "abbreviation": "SD"
    },
    {
        "name": "Tennessee",
        "abbreviation": "TN"
    },
    {
        "name": "Texas",
        "abbreviation": "TX"
    },
    {
        "name": "Utah",
        "abbreviation": "UT"
    },
    {
        "name": "Vermont",
        "abbreviation": "VT"
    },
    {
        "name": "Virginia",
        "abbreviation": "VA"
    },
    {
        "name": "Washington",
        "abbreviation": "WA"
    },
    {
        "name": "West Virginia",
        "abbreviation": "WV"
    },
    {
        "name": "Wisconsin",
        "abbreviation": "WI"
    },
    {
        "name": "Wyoming",
        "abbreviation": "WY"
    }
]

function EventStyledInput(props) {
    return <TextField fullWidth variant='outlined' {...props}/>
}

function Profile(props) {
    const theme = useTheme();
    const [preload, setPreload] = useState(true);
    const [redirect, setRedirect] = useState(false);
    const [navigation, setNavigation] = useState('account');
    const [loading, setLoading] = useState(false);
    const [pastEvents, setPastEvents] = useState();
    const [futureEvents, setFutureEvents] = useState();
    const [paidEvents, setPaidEvents] = useState();
    const context = useContext(AppContext); 
    const guardian = context.user || {players: []}; // allows user navigate to /profile directly
    const players = guardian.players;
    const lastUpdatedProfile = useRef(dayjs());
    
    useEffect(() => {
        // since updating the user context causes this component to re-render,
        // be sure to only set the guardian profile state if we didn't already set
        // it within the last 10 seconds
        if (lastUpdatedProfile.current.add(10, 'second').isBefore(dayjs())) {
            lastUpdatedProfile.current = dayjs();
            refresh_guardian_profile();
        }
        setNavigation('account');
        if (context.user) setPreload(false);
        if(context.user === undefined) {
            setRedirect(true);
            // temporary solution until I can resolve the Context state issues. 
            // possibly bring preloading state into context (for things like navbar, other elements)
            setTimeout(()=> setPreload(false), 3000); 
        }
    }, [])

    /* begin API functions */
    const get_profile = async () => {
        console.log('is this running?')
        return await pmp_axios.get('/user/profile', (res)=>res.data.profile);
    }

    const get_guardian_events = async () => {
        const res = await pmp_axios.get('/event/registration/list', (res)=>res.data);
        return res;
    }
    /* end API functions */

    /* begin helper functions */
    const sort_events_and_invoice = (payments) => {
        const pastEvents = payments.map((payment) => payment.event.hasPast);
        const futureEvents = payments.map((payment) => !payment.event.hasPast);
        const paidEvents = payments.map((payment) => payment.invoice.isPaid);

        setPastEvents(pastEvents);
        setFutureEvents(futureEvents);
        setPaidEvents(paidEvents);
    }

    const refresh_guardian_profile = async () => {
        const profile_res = await get_profile();
        const guardian_events = await get_guardian_events();
        sort_events_and_invoice(guardian_events?.parsed_result.payments);
        if (profile_res.success) {
            context.setUser(profile_res.parsed_result);
        } else {
            // TODO handle error
        }
    }

    const sign_out = () => {
        context.setUser(null);
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        setNavigation('signout');
    }
    /* end helper functions */

    /* begin rendering functions */

    function SubmissionButton(props) {
        return (
            <Grid container item xs={12}>
                <Grid item xs={5}/>
                <Grid item xs={2}>
                    <StyledButton variant='light' onClick={() => props.onClick()} style={{width: '100%'}} {...props}>{props.children}</StyledButton>
                </Grid>
                <Grid item xs={5}/>
            </Grid>
        );
    }

    function Account() {
        const [guardianInfo, setGuardianInfo] = useState({
            userId: guardian.id,
            first: guardian.first,
            last: guardian.last,
            birthdate: dayjs(guardian.birthDate).format('MM/DD/YYYY'),
            phone: guardian.phone,
            address: guardian.address,
            address2: guardian.address2,
            state: guardian.state,
            city: guardian.city,
            zip: guardian.zip
            // TODO add fields to profile page:
            // profilePictureLink: "string",
            // bio: "string",
            // positionIds: [
            //   0
            // ],
            // bioHtml: "string",
            // skillIds: [
            //   0
            // ],
            // club: "string"
        });
        const [generalText, setGeneralText] = useState('Update');
        const [addressText, setAddressText] = useState('Update');

        const setGuardianField = (field, value) => {
            setGuardianInfo({...guardianInfo, [field]: value});
        }

        const patch_profile = async (payload) => {
            const profileObj = payload;
            profileObj.birthdate = dayjs(profileObj.birthdate, 'MM/DD/YYYY')
            return await pmp_axios.patch('/user/profile', profileObj, (res)=>res.data.profile);
        }

        const update_address = async () => {
            const payload = {
                address: guardianInfo.address,
                state: guardianInfo.state,
                city: guardianInfo.city,
                zip: guardianInfo.zip
            }
            const guardian_res = await patch_profile(payload);
            if (guardian_res.success) {
                context.setUser(guardian_res.parsed_result);
                setAddressText('Updated')
            } else {
                // TODO handle error
            }
        }

        const update_general = async () => {
            const payload = {
                first: guardianInfo.first,
                last: guardianInfo.last,
                birthday: guardianInfo.birthdate,
                phone: guardianInfo.phone
            }
            const guardian_res = await patch_profile(payload);
            if (guardian_res.success) {
                context.setUser(guardian_res.parsed_result);
                setGeneralText('Updated')
            } else {
                // TODO handle error
            }
        }

        return (
            <Grid container spacing={3}>
                <Grid container item xs={6} spacing={1} alignContent='flex-start'>
                    <Grid container item xs={12} justify='space-between'>
                        <Grid item xs={1}>
                            <Typography>General</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <SubmissionButton onClick={() => update_general()} disabled={generalText === 'Updated'}>{generalText}</SubmissionButton>
                        </Grid>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <StyledInput helperText='First Name' value={guardianInfo.first} onChange={(value) => setGuardianField('first', value)}/>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <StyledInput helperText='Last Name' value={guardianInfo.last} onChange={(value) => setGuardianField('last', value)}/>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <NumberFormat helperText='DOB' format="##/##/####" allowEmptyFormatting mask="_" customInput={EventStyledInput} value={guardianInfo.birthdate} onValueChange={(v) => setGuardianField('birthdate', v.formattedValue)}/>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <NumberFormat helperText='Phone' format="(###)-###-####" allowEmptyFormatting mask="_" customInput={EventStyledInput} value={guardianInfo.phone} onValueChange={(v) => setGuardianField('phone', v.formattedValue)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <StyledInput helperText='Email' value={guardian.email} disabled onChange={(value) => setGuardianField('email', value)}/>
                    </Grid>
                </Grid>
                <Grid container item xs={6} spacing={1} alignContent='flex-start'>
                    <Grid container item xs={12} justify='space-between'>
                        <Grid item xs={1}>
                            <Typography>Address</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <SubmissionButton onClick={() => update_address()} disabled={addressText === 'Updated'}>{addressText}</SubmissionButton>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <StyledInput helperText='Address' value={guardianInfo.address} onChange={(value) => setGuardianField('address', value)}/>
                    </Grid>
                    <Grid item sm={5} xs={12}>
                        <StyledInput helperText='City' value={guardianInfo.city} onChange={(value) => setGuardianField('city', value)}/>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <StyledInput select helperText='State' value={guardianInfo.state} onChange={(value) => setGuardianField('state', value)} style={{textAlign: 'left'}} fullWidth SelectProps={{MenuProps:{PaperProps:{style: {maxHeight: 150, width: '20ch'}}}}}>
                            {states.map((state) => <SelectMenuItem key={state.abbreviation} value={state.abbreviation}>{state.abbreviation}</SelectMenuItem>)}
                        </StyledInput>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <NumberFormat helperText='Zip' format="#####" allowEmptyFormatting mask="" customInput={EventStyledInput} value={guardianInfo.zip} onValueChange={(v) => setGuardianField('zip', v.formattedValue)}/>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    function Settings(props) {
        const [currentPassword, setCurrentPassword] = useState();
        const [newPassword, setNewPassword] = useState();
        const [confirmPassword, setConfirmPassword] = useState();
        const [message, setMessage] = useState();
        const [buttonText, setButtonText] = useState('RESET');

        const validate_password = () => {
            if (newPassword !== confirmPassword) return 'Passwords Do Not Match';
            return 'success';
        }

        const post_password = async () => {
            const passwordObj = {
                currentPassword: currentPassword,
                newPassword: newPassword
            }
            return await pmp_axios.post('/user/changePassword', passwordObj);
        }

        /* Ensure that every field has a value entered before trying to reset PW */
        const validate_form = () => {
            const currentCheck = currentPassword && currentPassword.length > 0;
            const newCheck = newPassword && newPassword.length > 0;
            const confirmCheck = confirmPassword && confirmPassword.length > 0;
            return currentCheck && newCheck && confirmCheck;
        }

        const change_password = async () => {
            const validation = validate_password();
            if (validation === 'success') {
                const passwordRes = await post_password();
                if (passwordRes.success) {
                    setButtonText('SUCCESS');
                    // Also clear any error message that might be there and set all fields to blank
                    setMessage('');
                    setCurrentPassword('');
                    setNewPassword('');
                    setConfirmPassword('');
                } else {
                    setMessage(passwordRes.error);
                }
            } else {
                setMessage(validation);
            }
        }

        return (
            <Grid container spacing={3}>
                <Grid container item xs={6} spacing={1} alignContent='flex-start'>
                    <Grid container item xs={12} justify='space-between'>
                        <Grid item xs={1}>
                            <Typography>Reset Password</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <SubmissionButton onClick={() => change_password()} disabled={buttonText==='SUCCESS' || !validate_form()}>{buttonText}</SubmissionButton>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <StyledInput type="password" helperText='Current Password' value={currentPassword ?? ''} onChange={(v) => setCurrentPassword(v)}/>
                    </Grid>
                    <Grid item xs={6}>
                        <StyledInput type="password" helperText='New Password' value={newPassword ?? ''} onChange={setNewPassword}/>
                    </Grid>
                    <Grid item xs={6}>
                        <StyledInput type="password" helperText='Confirm Password' value={confirmPassword ?? ''} onChange={setConfirmPassword}/>
                    </Grid>
                    {message && <Grid item xs={12}>
                            <Typography variant='body1' color='red'>{message}</Typography>
                    </Grid>}
                </Grid>
            </Grid>
        );
    }

    function Financial() {
        return (
            <Grid container>
                <Grid item xs={12}>
                    {paidEvents ? 
                    <div>
                        <Typography variant='h3'>Past Payments</Typography>
                        {paidEvents?.map((paidEvent)=> {
                            <div>
                                <Typography variant="h5">{paidEvent.event.name}</Typography>
                                <Typography variant="body1">
                                    <Link variant='body2' color='gray' href={paidEvent.invoice.receiptUrl} style={{margin: 20, cursor: 'pointer'}}>${paidEvent.invoice.amount}</Link>
                                </Typography>
                            </div>
                        })}
                    </div>
                    :
                    <Typography variant='h3'>You haven't made any payments yet.</Typography>
                    }
                </Grid>
            </Grid>
        );
    }

    function Events() {
        return (
            <Grid container>
                <Grid item xs={12}>
                    {pastEvents || futureEvents ? 
                    <div>
                        <Typography variant='h3'>Future Events</Typography>
                        {futureEvents?.map((event)=> {
                            <Typography variant="body1">{event.name}</Typography>
                        })}
                        <Typography variant='h3'>Past Events</Typography>
                        {pastEvents?.map((event)=> {
                            <Typography variant="body1">{event.name}</Typography>
                        })}
                    </div>
                    :
                    <Typography variant='h3'>You don't have any events yet.</Typography>
                    }
                </Grid>
            </Grid>
        );
    }

    function Players() {
        const [formInfo, setFormInfo] = useState({
            playerFirst: '',
            playerLast: '',
        });

        const update_form_info = (field, value) => {
            setFormInfo({...formInfo, [field]: value});
        }    

        const post_player = async () => {
            const playerObj = {
              first: formInfo.playerFirst,
              last: formInfo.playerLast,
              email: guardian.email, // TODO allow players to have their own email
            };
            return await pmp_axios.post('/user/player', playerObj, (res)=>res.data.player);
        }
    
        const handle_add_player = async () => {
            setLoading(true);
            const posted_player = await post_player();
            if (posted_player.success) {
                refresh_guardian_profile();
                setFormInfo({});
            }
            else alert('Failed to add player.')
            setLoading(false);
        }

        return (
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant='h3'>Players</Typography>
                </Grid>
                <Grid container item xs={12} justifyContent='center' style={{marginTop: '1.5rem'}}>
                    {players.map((player) => {return (
                        <Grid key={player.id} item xs={12/players.length}>
                            <FormControlLabel control={<ToggleButton 
                                name={player.id.toString()}
                                value="check"
                                selected={false}
                                >{player.first}</ToggleButton>
                            }/>
                        </Grid>
                    );})}
                </Grid>
                <Grid item xs={12} align='center' style={{marginTop: '2rem'}}>
                    <Grid item xs={12}>
                        <Typography variant='h3'>Add Player</Typography>
                    </Grid>
                    {/* Player Info & Password */}
                    <Grid container item xs={6} justify="space-evenly" spacing={1}>
                        {/* <Grid container item direction="row" spacing={spacingNum}> */}
                        <Grid item container xs={12} spacing={2}  style={{margin: '1rem'}}>
                            <Grid item xs={6}>
                                <StyledInput onChange={v => update_form_info('playerFirst', v)} value={formInfo.playerFirst} placeholder='Player First'/>
                            </Grid>
                            <Grid item xs={6}>
                                <StyledInput onChange={v => update_form_info('playerLast', v)} value={formInfo.playerLast} placeholder='Player Last'/>
                            </Grid>
                        </Grid>
                        <Grid item container direction="row">
                            <Grid item xs={1}/>
                            <Grid item xs={1}>
                                <CircularProgress style={{marginTop: '0rem', visibility: loading ? 'visible' : 'hidden'}} color="" />
                            </Grid>
                            <Grid item xs={8}>
                                <StyledButton fullWidth onClick={() => handle_add_player()} style={{height: 40}}>Create Player</StyledButton>
                            </Grid>
                            <Grid item xs={2}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    function MenuItem(props) {
        return (
            <Grid container>
                <Grid item xs={2}>
                    {props.icon()}
                </Grid>
                <Grid item xs={8}>
                    <Link variant='body2' color='gray' onClick={() => setNavigation(props.nav)} style={{margin: 20, cursor: 'pointer'}}>
                        {props.text}
                    </Link>
                </Grid>
            </Grid>
        );
    }

    function Bio() {
        return (
            <Grid container justify="center" alignContent="center" spacing={0} style={{height: '100%'}}>
                <Grid item xs={12}>
                    <img src={logo} alt="logo" style={{height: 125, width: 125, borderRadius: '50%', border: '2px solid black'}}/>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">{`${guardian.first} ${guardian.last}`}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Link variant="body2" color='gray' onClick={() => sign_out()} style={{cursor: 'pointer'}}>Sign Out</Link>
                </Grid>
            </Grid>
        );
    }

    function SkeletonBio() {
        return (
            <Grid container justify="center" alignContent="center" spacing={0} style={{height: '100%'}}>
                <Grid item xs={12} container>
                    <Grid xs={3} />
                    <Grid xs={6} item>
                        <Skeleton variant="circular" style={{height: 125, width: 125, borderRadius: '50%'}}/>
                    </Grid>
                    <Grid xs={3} />
                </Grid>
                <Grid item xs={12} container>
                    <Grid xs={4} />
                    <Grid xs={6} item>
                    <Skeleton>
                        {/* 'filler' text is just to widen the skeleton element. 
                            Applying width={num} can mess with the height, oddly  */}
                        <Typography variant="body1">fillerfillerfill</Typography> 
                    </Skeleton>
                    </Grid>
                    <Grid xs={3} />
                </Grid>
                <Grid item xs={12} container>
                    <Grid xs={5} />
                    <Grid xs={4} item>
                    <Skeleton marginLeft={'15%'}>
                        <Link variant="body2" color='gray'>Sign Out</Link>
                    </Skeleton>
                    </Grid>
                    <Grid xs={4} />
                </Grid>
            </Grid>
        );
    }

    function ProfileSelectionMenu() {
        return (
            <Grid container alignContent='center' spacing={3}>
                <Grid item xs={12}>
                    <MenuItem text='My Account' nav='account' icon={() => <PersonIcon color='disabled'/>}/>
                </Grid>
                <Grid item xs={12}>
                    <MenuItem text='Settings' nav='settings' icon={() => <SettingsIcon color='disabled'/>}/>
                </Grid>
                {/* Uncomment these one at a time as we build out the functionality */}
                {/* <Grid item xs={12}>
                    <MenuItem text='Financial' nav='financial' icon={() => <AccountBalance color='disabled'/>}/>
                </Grid> */}
                {/* <Grid item xs={12}>
                    <MenuItem text='Events' nav='events' icon={() => <Event color='disabled'/>}/>
                </Grid> */}
                <Grid item xs={12}>
                    <MenuItem text='Players' nav='players' icon={() => <SupervisorAccount color='disabled'/>}/>
                </Grid>
            </Grid>
        );
    }

    function SkeletonProfileSelectionMenu() {
        return (
            <Grid container alignContent='center' spacing={3}>
                <Grid item xs={12}>
                    <Skeleton><MenuItem icon={() => <PersonIcon/>}/></Skeleton>
                </Grid>
                <Grid item xs={12}>
                    <Skeleton><MenuItem icon={() => <SettingsIcon/>}/></Skeleton>
                </Grid>
                <Grid item xs={12}>
                    <Skeleton><MenuItem icon={() => <AccountBalance/>}/></Skeleton>
                </Grid>
                <Grid item xs={12}>
                    <Skeleton><MenuItem icon={() => <Event/>}/></Skeleton>
                </Grid>
                <Grid item xs={12}>
                    <Skeleton><MenuItem icon={() => <SupervisorAccount/>}/></Skeleton>
                </Grid>
            </Grid>
        );
    }

    function ProfileSection() {
        const nav = navigation;
        return (
            <Grid container item style={{height: 300}}>
                {
                    nav === 'signout' ?
                        <Redirect push to={'/'} /> :
                    nav === 'account' ?
                        <Account/> :
                    nav === 'settings' ?
                        <Settings/> :
                    nav === 'financial' ?
                        <Financial/> :
                    nav === 'events' ?
                        <Events/> :
                    nav === 'players' ?
                        <Players/> :
                    <div/>
                }
            </Grid>
        );
    
    }
    function SkeletonProfileSection() {
        return (
            <Grid container item style={{height: 300}}>
                <Grid container spacing={3}>
                    <Grid container item xs={6} spacing={1} alignContent='flex-start'>
                        <Grid container item xs={12} justify='space-between'>
                            <Grid item xs={1}>
                                <Typography><Skeleton width={150} height={40}/></Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Skeleton><SubmissionButton>filler</SubmissionButton></Skeleton>
                            </Grid>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <Skeleton><StyledInput/></Skeleton>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <Skeleton><StyledInput/></Skeleton>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <Skeleton><StyledInput/></Skeleton>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <Skeleton><StyledInput/></Skeleton>
                        </Grid>
                        <Grid item xs={12}>
                            <Skeleton width={'100%'}><StyledInput/></Skeleton>
                        </Grid>
                    </Grid>
                    <Grid container item xs={6} spacing={1} alignContent='flex-start'>
                        <Grid container item xs={12} justify='space-between'>
                            <Grid item xs={1}>
                                <Typography><Skeleton width={150} height={40}/></Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Skeleton><SubmissionButton>filler</SubmissionButton></Skeleton>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Skeleton width={'100%'}><StyledInput /></Skeleton>
                        </Grid>
                        <Grid item sm={5} xs={12}>
                            <Skeleton><StyledInput /></Skeleton>
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <Skeleton><StyledInput /></Skeleton>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <Skeleton><StyledInput /></Skeleton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    const Preloader = () => (
        <Box style={{padding: '5rem 0'}}>
            <Grid container justify='flex-start' spacing={2}>
                <Grid item lg={2} md={6}>
                    <SkeletonBio />
                </Grid>
                <Grid container item lg={2} md={6}>
                    <SkeletonProfileSelectionMenu />
                </Grid>
                <Grid container item lg={8} md={9} sm={10} xs={11}>
                    <SkeletonProfileSection />
                </Grid>
            </Grid>
        </Box>
      )
    /* end rendering functions */

    if (preload){
        return <Preloader/>
    } else if (redirect) {
        return <Redirect push to='/'/>
    }
    else
    return (
        <Box style={{padding: '5rem 0'}}>
            <Grid container justify='flex-start' spacing={2}>
                <Grid item lg={2} md={6}>
                    <Bio />
                </Grid>
                <Grid container item lg={2} md={6}>
                    <ProfileSelectionMenu />
                </Grid>
                <Grid container item lg={8} md={9} sm={10} xs={11}>
                    <ProfileSection />
                </Grid>
            </Grid>
        </Box>
    );
}

export default Profile;