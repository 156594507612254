import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@material-ui/core';
import EventsFilter from '../components/EventsFilter.js'
import Carousel from '../components/Carousel';
import Banner from '../components/Banner';
import pmp_axios from '../utils/pmp_axios' // for use with future endpoint
import image_url from '../assets/pmp_banner.jpeg';

const samplePhotoUrl = 'https://images.unsplash.com/photo-1604336732494-d8386c7029e3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDZ8fHxlbnwwfHx8fA%3D%3D&w=1000&q=80';

function Trainers(props) {
    const default_filters = props.location?.state?.filters;
    const [events, setEvents] = useState([]);
    const [trainers, setTrainers] = useState([]);
    const { domain } = props;

    // this is for later once this endpoint exists
    useEffect(() => {        
        set_trainers();              
    },[]);

    const get_trainers = async () => {
        return await pmp_axios.get('/trainer/', (res)=>res.data.profiles);
    }

    const set_trainers = async () => {
        const trainer_resp = await get_trainers();
        if(trainer_resp.success){
            setTrainers(trainer_resp.parsed_result);
        }
    }

    return (
        <Box>
            <Grid container item xs={12} justify='space-around' direction="row">
            {
                domain.host === 'pmp' ?
                
                    <Grid item container xs={6} alignItems="center" justify="center" style={{minHeight: '35vh'}}> 
                        <EventsFilter onEventsChange={(events) => setEvents(events)} updateMode='partial' defaultFilters={default_filters}/>
                    </Grid>                
                : ""
            }            
            </Grid>
            <Grid container direction="row" justify="space-around" alignItems="center">
                {
                    trainers?.length > 0 ?
                        <Carousel carouselTitle="Our Trainers" data={trainers} slideType="trainer" />
                    :
                    "There are no available trainers."
                }                
            </Grid>
            <Grid container direction="row" justify="space-around" style={{padding: '1rem', backgroundColor: '#fffaf5'}}>
                        <Banner title={domain.bottomBannerTitle} bannerImage={domain.bottomBannerImage} linkText={domain.bottomBannerLinkText}/>
            </Grid>
            <Grid style={{minHeight: '10vh'}} />
        </Box>
    );
}

export default Trainers;
