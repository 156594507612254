import React, { useRef } from 'react';

import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import Slider from 'react-slick';
import {FaChevronLeft, FaChevronRight} from 'react-icons/fa'

import Slide from './Slide';

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function Carousel(props) {
    const slider = useRef(null);
    const data = props.data || [];
    const slideType = props.slideType;

    /*
        TODO: check and see if the useStyles styling is in pmp_theme, 
        if it needs to be added, or is fine right here.
    */
    const useStyles = makeStyles({
        root: {
            backgroundColor: '#fffaf5', // replace with theme.pallette.primary.main
            minHeight: '300px',
            position: 'relative',
            width: '100%',
            overflow: 'hidden',
        },
        carouselTitle: {
            justifyContent: "flex-end",
        },
        chevronButton: {
            backgroundColor: 'rgba(0,0,0,0)',
            border: '#000 1px solid',
            borderRadius: '50%',
            height: '2.5em',
            width: '2.5em',
            marginLeft: '0.5em',
        },
    });
    
    // Slider default settings, breakpoints, and # of slides to show at each breakpoint
    var settings = {
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: Math.min(data?.length,5),
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1324,
              settings: {
                slidesToShow: Math.min(data?.length,4),
                slidesToScroll: 4,
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: Math.min(data?.length,3),
                slidesToScroll: 3,
              }
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: Math.min(data?.length,2),
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: Math.min(data?.length,1),
                slidesToScroll: 1
              }
            }
        ]        
      };

    const classes = useStyles();
    if(data){    
        return (
            <Box p={2} sx={{ height: '25%', width: '100%' }} className={classes.root}>
                <Grid container>
                    <Grid container direction="row">
                        <Grid item xs={6}>
                            <Typography variant='body1' justify="flex-start" align='left'>{props.carouselTitle}</Typography>
                        </Grid>
                        <Grid item xs={6} className={classes.carouselTitle}>
                            {                                
                                    <div className='controls' align='right'>
                                        <Typography style={{display: 'inline'}}>See More</Typography>
                                        <button className={classes.chevronButton} onClick={() => slider?.current?.slickPrev()}>
                                            <FaChevronLeft />
                                        </button>
                                        <button className={classes.chevronButton} onClick={() => slider?.current?.slickNext()}>
                                            <FaChevronRight />
                                        </button>
                                    </div>                       
                            }
                            
                        </Grid>
                    </Grid>
                    <Grid container direction="row" item xs={12}>
                        <Slider variableWidth={true} style={{width: '100%'}} ref={slider} {...settings}>
                            {
                            slideType === "event" 
                            ?     
                            data?.map((event, i) => <Slide key={i} slideType={slideType} {...event}/> )
                            :
                            data?.map((trainer, i) => <Slide key={i} slideType={slideType} {...trainer}/> )
                            }
                        </Slider>
                    </Grid>
                </Grid>
            </Box>
        );     
    }else{
        return null;
    }   
}

export default Carousel;
