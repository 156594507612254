import React, { useState } from 'react';

import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import Image from 'material-ui-image';
import StyledButton from './StyledButton';
import image_url from '../assets/pmp_banner.jpeg';
import dayjs from "dayjs";
import { Redirect } from "react-router-dom";

function Slide({...data}) {
    const [redirect, setRedirect] = useState(false);
    const image = data.slideType === 'event' ? data.imageUrl: data.profilePictureLink;    

    /*
        TODO: check and see if the useStyles styling is in pmp_theme, 
        if it needs to be added, or is fine right here.
    */
    const useStyles = makeStyles({
        root: {
            minHeight: '10rem',
            minWidth: '13rem'
        },
        imageWrapper: {
            minHeight: '16rem',
            position: 'relative'
        },
        eventImage: {
            borderRadius: '10px',
            objectFit: 'cover !important',
            minHeight: '15rem',
        },
        date: {
            fontSize: '0.8rem',
            margin: 'auto 0',
        },
        eventButton: {
            float: 'right',
        },
        btn: {
            fontSize: '0.7rem',
        },
        pill: {
            backgroundColor: '#FFFAF5',
            height: '1rem',
            position: 'absolute',
            top: '0.3rem',
            right: '0.3rem',
            borderRadius: '4px',
        },
        pillWrapper: {
            lineHeight: 0.6
        },
        pillText: {
            fontSize: '0.4rem',
            color: '#aeaeae',
            padding: '3px',
            textTransform: 'uppercase',
        }
    });

    const classes = useStyles();

    /*
        Event = Clinic or Trainer. It's just the main "thing" of the card.

        Slide has three rows.
        - Top:      image and sport pill
        - Middle:   event name and cost
        - Bottom:   event date and sign up button 

        TODO: pass in link in sign up button to URL of the event
    */ 
    if (redirect) {
        const path = data.slideType === 'event' 
            ? data.name + '-' + data.id 
            : data.first + '-' + data.last + '-' + data.id;
        
        const pathUrl = path.toLowerCase().replaceAll(' ', '-');

        return <Redirect push to={{ pathname: `/${data.slideType}/${pathUrl}`, state: {data: data, entityId: data.id} }}/>
    } else return (
        <Box m={1} p={1} className={classes.root}>
            <Grid container direction="column">
                <Grid container direction="row" className={classes.imageWrapper}>
                    <Grid item xs={12}>
                        <Image src={image || image_url} className={classes.eventImage} alt="event"/>
                    </Grid>
                    {/* <Box className={classes.pill}>
                        <Typography className={classes.pillWrapper} variation='body2'><span className={classes.pillText}>{data.sport ? data.sport : "Lacrosse" }</span></Typography>
                    </Box> */}
                </Grid>
                <Grid container direction="row" style={{marginBottom: '0.4rem'}}>
                    <Grid item xs={10}>
                        <Typography variant="body1" align='left' style={{margin: '0.4rem 0'}}>{data.name || data.first + ' ' + data.last}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body1" align='right' style={{margin: '0.4rem 0'}}>{data.registrationCost ? "$" + data.registrationCost : ""}</Typography>
                    </Grid>
                </Grid>
                <Grid container direction="row">
                    <Grid item xs={8} style={{display: 'flex'}}>
                        <Typography variant="body2" align='left' className={classes.date}>{data.slideType === "event" ? dayjs(data.endDate).format('MM/DD') : data.club}</Typography>
                    </Grid>
                    <Grid item xs={4} alignItems="flex-end" align='right'>
                        <StyledButton className={classes.eventButton} onClick={() => setRedirect(true)}>
                            <span className={classes.btn}>{ data.slideType == 'trainer' ? 'Bio' : 'Sign up'}</span>
                        </StyledButton>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Slide;
