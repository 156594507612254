import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import EventsFilter from '../components/EventsFilter';
import StyledButton from '../components/StyledButton';
import Content from '../components/Content';

function About() {
	const [redirect, setRedirect] = useState();
	const [filters, setFilters] = useState();

	if (redirect) {
		return <Redirect push to={{ pathname: "/upcoming"}}/>;
	} else if (filters) {
		return <Redirect push to={{ pathname: "/upcoming", state: {filters: filters} }}/>;
	} else {
		return (
			<Content>
				<Grid container justify="center" alignItems="center">
					<Grid item md={8} xs={10} container direction="column" justify="center" alignItems="center" spacing={4}>
						<Grid item>
							<EventsFilter onSubmission={(filters) => setFilters(filters)}></EventsFilter>
						</Grid>
						<Grid item>
							<Typography variant='h2' align='center'>About Us</Typography>
						</Grid>
						<Grid item>
							<Typography variant='body1' align='center'>
							         						
							</Typography>
						</Grid>
						<Grid item>
							<StyledButton align='center' variant='light-border' onClick={() => setRedirect(true)}>GET STARTED</StyledButton>
						</Grid>
					</Grid>
				</Grid>
			</Content>
		);
	}
}

export default About;

function Lorem() {
	return 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';
}
