import React, { useContext, useState } from 'react';
import { Grid, Typography, Link, CircularProgress } from '@material-ui/core';
import AppContext from '../context';
import StyledButton from './StyledButton';
import StyledModal from './StyledModal';
import StyledInput from './StyledInput';
import pmp_axios from '../utils/pmp_axios'

function AuthModal(props) {
  const context = useContext(AppContext);
  const domain = context.domain;
  const [toggle, setToggle] = useState(false);
  const [formInfo, setFormInfo] = useState({});
  const [signInEmail, setSignInEmail] = useState();
  const [signInPassword, setSignInPassword] = useState();
  const [signUpErrorText, setSignUpErrorText] = useState();
  const [signInErrorText, setSignInErrorText] = useState();
  const [loading, setLoading] = useState(false);

  /* begin API functions */
  const post_guardian = async () => {
    const guardianObj = {
      first: formInfo.guardianFirst,
      last: formInfo.guardianLast,
      email: formInfo.email,
      password: formInfo.password,
    };
    return await pmp_axios.post('/user/guardian', guardianObj, (res)=>res.data.user);
  }

  const post_player = async () => {
    const playerObj = {
      first: formInfo.playerFirst,
      last: formInfo.playerLast,
      email: formInfo.email, // TODO allow players to have their own email
    };
    return await pmp_axios.post('/user/player', playerObj, (res)=>res.data.player);
  }

  const post_signin = async () => {
    const signInObj = {
      email: signInEmail,
      password: signInPassword 
    };
    return await pmp_axios.post('/user/signIn', signInObj, (res)=>res.data.user);
  };

  const get_profile = async () => {
    return await pmp_axios.get('/user/profile', (res)=>res.data.profile);
  };
  /* end API functions */

  /* begin helper functions */
  const update_form_info = (field, value) => {
    setFormInfo({...formInfo, [field]: value});
  }

  const signin = async () => {
    const signin_res = await post_signin();
    if (signin_res.success) {
      localStorage.setItem("accessToken", signin_res.parsed_result.accessToken);
      localStorage.setItem("refreshToken", signin_res.parsed_result.refreshToken);
      const profile_res = await get_profile();
      if (profile_res.success) {
        context.setUser(profile_res.parsed_result);
        return 'success';
      } else {
        return 'Failed to get user info, please contact support.'
      }
    } else {
      return signin_res.error;
    }
  }

  const signup = async () => {
    const guardian_res = await post_guardian();
    if (guardian_res.success) {
      localStorage.setItem("accessToken", guardian_res.parsed_result.accessToken);
      localStorage.setItem("refreshToken", guardian_res.parsed_result.refreshToken);
    } else {
      return guardian_res.error;
    }

    const player_res = await post_player();
    if (player_res.success) {
      // be happy there was no error
    } else {
      return player_res.error;
    }

    const profile_res = await get_profile();
    if (profile_res.success) {
      context.setUser(profile_res.parsed_result);
      return 'success';
    } else {
      return 'Failed to get user info, please contact support.';
    }
  };

  const validate_signup = () => {
    var {email, password, confirmPassword, guardianFirst, guardianLast, playerFirst, playerLast} = formInfo;
    const emailRe = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRe.test(email.toLowerCase())) return 'Invalid Email';

    // const passwordRe = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    // if (!passwordRe.test(password)) return 'Invalid Password: Must contain upper, lower, digit, special character, and at least six characters';

    if (password !== confirmPassword) return 'Passwords Do Not Match';

    if (!guardianFirst || guardianFirst === '') return 'Invalid Guardian First Name';
    if (!guardianLast || guardianLast === '') return 'Invalid Guardian Last Name';
    if (!playerFirst || playerFirst === '') return 'Invalid Player First Name';
    if (!playerLast || playerLast === '') return 'Invalid Player Last Name';
    return 'valid';
  }
  /* end helper functions */

  /* begin event functions */
  const handle_signup = async () => {
    setLoading(true);
    const valid = validate_signup();
    if (valid === 'valid') {
      const signup_text = await signup();
      if (signup_text === 'success') {
        handle_close();
        setSignUpErrorText(null);
        setFormInfo({});
      } else {
        setSignUpErrorText(signup_text);
      }
    } else {
      setSignUpErrorText(valid);
    }
    setLoading(false);
  }

  const handle_signin = async () => {
    setLoading(true);
    const signin_text = await signin();
    if (signin_text === 'success') {
      handle_close();
      setSignInErrorText(null);
    } else {
      setSignInErrorText(signin_text);
      setSignInEmail('');
      setSignInPassword('');
    }
    setLoading(false);
  }

  const handle_close = () => {
    setFormInfo({});
    setToggle(false);
    setSignInEmail('');
    setSignInPassword('');
    setSignInErrorText(null);
    setSignUpErrorText(null);
    props.handleClose();
  }
  /* end event functions */

  /* begin rendering functions */
  function SignIn() {
    return (
      <StyledModal open={props.open} onClose={handle_close} style={{minHeight: '80%', marginTop: '5%'}}>
        <Grid container justify='center' alignItems="center" spacing={3}>
          <Grid item>
            <img src={domain.logo} alt="logo" style={{height: '80px'}}/>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" align="center">Sign in to get started.</Typography>
          </Grid>
          <Grid item md={8} xs={12}>
            <StyledInput onChange={setSignInEmail} value={signInEmail} placeholder='Email'/>
          </Grid>
          <Grid item md={8} xs={12}>
            <StyledInput onChange={setSignInPassword} value={signInPassword} type="password" placeholder='Password'/>
          </Grid>
          {signInErrorText &&
            <Grid item>
              <Typography variant='body2' style={{color: 'red'}}>{signInErrorText}</Typography>
            </Grid>
          }
          <Grid item xs={12}>
            <Typography variant="body2" align="center">By signing in, you agree to {domain.companyName}'s <Link href="/privacy" target="_blank" color="inherit" style={{textDecoration: 'underline', cursor: 'pointer'}} >Privacy Policy</Link> and <Link href="/terms" target="_blank" color="inherit" style={{textDecoration: 'underline', cursor: 'pointer'}} >Terms of Use</Link>.</Typography>
          </Grid>
          <Grid item xs={8}>
            <StyledButton fullWidth onClick={() => handle_signin()} style={{minHeight: 40}}>Sign In</StyledButton>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={2}/>
            <Grid item xs={1}>
              <CircularProgress style={{marginTop: '-0.5rem', visibility: loading ? 'visible' : 'hidden'}} color="" />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" align="center">Don't have an account? <Link color="inherit" style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={()=>setToggle(true)}>Sign up here.</Link></Typography>
            </Grid>
            <Grid item xs={3}/>
          </Grid>
        </Grid>
      </StyledModal>
    );
  }

  function SignUp() {
    const spacingNum = 1;
    return (
      <StyledModal open={props.open} onClose={handle_close} style={{minHeight: '90%', marginTop: '2%'}}>
        <Grid container xs={12} justify='center' spacing={3}>
          <Grid item>
            <img src={domain.logo} alt="logo" style={{height: '80px'}}/>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" align="center">Sign up for an account.</Typography>
          </Grid>
          {/* Guardian Info */}
          <Grid container item xs={11} justify="space-evenly" spacing={spacingNum}>
            <Grid container item direction="row" spacing={spacingNum}>
              <Grid item xs={6}>
                <StyledInput onChange={v => update_form_info('guardianFirst', v)} value={formInfo.guardianFirst} placeholder='Guardian First'/>
              </Grid>              
              <Grid item xs={6}>
                <StyledInput onChange={v => update_form_info('guardianLast', v)} value={formInfo.guardianLast} placeholder='Guardian Last'/>
              </Grid>
            </Grid>
            <Grid container item direction="row" spacing={spacingNum}>
            <Grid item xs={6} >
                <StyledInput onChange={v => update_form_info('email', v)} value={formInfo.email} placeholder='Guardian Email'/>
              </Grid>
              <Grid item xs={6}/>
            </Grid>
            <Grid container item direction="row" spacing={spacingNum}>              
              <Grid item xs={6}>
                <StyledInput onChange={v => update_form_info('password', v)} type="password" value={formInfo.password} placeholder='Password'/>
              </Grid>
              <Grid item xs={6}>
                <StyledInput onChange={v => update_form_info('confirmPassword', v)} type="password" value={formInfo.confirmPassword} placeholder='Confirm Password'/>
              </Grid>
            </Grid>
          </Grid>          
          {/* Player Info */}
          <Grid container item xs={11} justify="space-evenly" spacing={spacingNum}>
            <Grid container item direction="row" spacing={spacingNum}>
              <Grid item xs={6}>
                <StyledInput onChange={v => update_form_info('playerFirst', v)} value={formInfo.playerFirst} placeholder='Player First'/>
              </Grid>
              <Grid item xs={6}>
                <StyledInput onChange={v => update_form_info('playerLast', v)} value={formInfo.playerLast} placeholder='Player Last'/>
              </Grid>
            </Grid>            
          </Grid>
          <Grid container item xs={12} justify="center">
            {/* Confirmation Button and spinner */}
            <Grid container direction="row">
              <Grid item xs={1}/>
              <Grid item xs={1}>
                <CircularProgress style={{marginTop: '0rem', visibility: loading ? 'visible' : 'hidden'}} color="inherit" />
              </Grid>
              <Grid item xs={8}>
                <StyledButton fullWidth onClick={() => handle_signup()} style={{height: 40}}>Create Account</StyledButton>
              </Grid>
              <Grid item xs={2}/>
            </Grid>
          </Grid>
          {signUpErrorText &&
          <Grid item xs={8}>
            <Typography variant='body2' style={{color: 'red'}}>{signUpErrorText}</Typography>
          </Grid>
          }
        </Grid>
      </StyledModal>
    );
  }
  /* end rendering functions */

  return (
    toggle ? SignUp() : SignIn()
  );
}

export default AuthModal;
