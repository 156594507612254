import React, { useState, useContext, useEffect } from 'react';
import { Box, Grid, Typography, Hidden, TextField, Card, Checkbox, FormGroup, FormControlLabel, makeStyles } from '@material-ui/core';
import Image from 'material-ui-image';
import { Redirect } from 'react-router-dom';
import StyledButton from '../components/StyledButton';
import StyledModal from '../components/StyledModal';
import StyledDialog from '../components/StyledDialog';
import AuthButton from '../components/AuthButton';
import Content from '../components/Content';
import AppContext from '../context';
import { FaChevronLeft } from 'react-icons/fa'
import pmp_axios from '../utils/pmp_axios';
import Carousel from '../components/Carousel';

const samplePhotoUrl = 'https://images.unsplash.com/photo-1604336732494-d8386c7029e3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDZ8fHxlbnwwfHx8fA%3D%3D&w=1000&q=80';

function Trainer(props) {
    const [redirectToTrainers, setRedirectToTrainers] = useState(false);
    const [skillString, setSkillString] = useState();
    const [trainer, setTrainer] = useState(props.location?.state?.data); // from redirect
    const context = useContext(AppContext);
    const [trainers, setTrainers] = useState()

    const useStyles = makeStyles({
        trainer: {
            width: '80%',
            height: '9.5rem',
            border: '1px black solid',
            borderRadius: '10px',
            backgroundColor: 'rgba(0,0,0,0)',
            margin: 10, 
            padding: 10,
            display: 'inline-block',
        },
        trainerText: {
            paddingLeft: '0.4rem'
        },
        chevronButton: {
            backgroundColor: 'rgba(0,0,0,0)',
            border: '#000 1px solid',
            borderRadius: '50%',
            height: '2.5em',
            width: '2.5em',
            marginRight: '0.5em',
            cursor: 'pointer',
        },
        backButton: {
            position: 'absolute',
            top: '1rem',
            left: '1rem',
        },
        skillPill: {
            // border: `1px solid #000`, 
            // borderRadius: 5, 
            verticalAlign: 'middle',
            padding: '0.2rem',
            color: '#AEAEAE',
            fontSize: '0.75rem',
            fontFamily: 'axia',
        },
    });

    const classes = useStyles();
    
    useEffect(()=>{
        // direct link
        if (!trainer && setTrainer) {
            const pathname = props.location.pathname;
            const pathitems = pathname.split('-');
            const trainer_id = pathitems[pathitems.length-1];
            set_trainer(trainer_id);                        
        }else{
            set_trainer_skills(trainer.skills);
        }
        if(!trainers && setTrainers){
            set_trainers();
        }        
    },[]);
    
    /* begin API functions */
    const get_trainer = async (id) => {
        return await pmp_axios.get('/trainer/'+id, (res)=>res.data.profile);
    }
    /* end API functions */

    /* begin helper functions */
    const get_names = (items) => {
        const names = items.reduce((acc, item, index)=>{
            if (index > 0) acc += ', ';
            return acc += item.name
        }, '')
        return names
    }

    const set_trainer = async (id) => {
        const trainer_res = await get_trainer(id);
        if (trainer_res.success) {
            setTrainer(trainer_res.parsed_result);
            set_trainer_skills(trainer_res.parsed_result.skills);          
        } else {
            // TODO handle error
        }
    }

    //turn list of skills into string
    const set_trainer_skills = async (skills) => {        
        if(skills?.length) {
            let string = '';
            skills.forEach((skill) => {
                string += skill.name + ', '
            })
            string = string.slice(0, string.length - 2);
            setSkillString(string.toUpperCase());
        }
    }

    const set_trainers = async () => {
        const trainers_resp = await get_trainers();
        if(trainers_resp.success){
            setTrainers(trainers_resp.parsed_result);            
        }
    }

    const get_trainers = async () => {
        return await pmp_axios.get('/trainer/', (res)=>res.data.profiles);
    }

    const has_empty_field = (array) => {
        let there_exists_empty = false;
        array.forEach(element => {
            for (const property in element) {
                if (element[property] === null || element[property] === "") {
                    there_exists_empty = true;
                }
            }
        });
        return there_exists_empty;
    }
    /* end helper functions */

    /* begin rendering functions */
    function Event(props) {
        const { event } = props;

        return (
            <Card className={classes.trainer}>
                <Grid container xs={12} direction="row">
                    <Grid item xs={5}>
                        <Image src={event.imageUrl} cover alt={event.name} style={{height: '30%', borderRadius: '4px', maxWidth: '100%'}}/>
                    </Grid>
                    <Grid item xs={7} container direction="column" justify="space-between" alignItems="flex-start" className={classes.trainerText}>
                        <Grid item>
                            <Typography variant='h5' style={{textAlign: 'left', fontSize: '1rem'}}>{event.name}</Typography>
                        </Grid>
                        <Grid item alignItems="flex-start">
                            <Typography variant='subtitle2' style={{textAlign: 'left', fontSize: '0.8rem'}}>Starts {event.date} / ${event.price}</Typography>
                        </Grid>
                        <Grid item alignItems="flex-end" flex={1} style={{marginTop: '2rem'}}>
                            <StyledButton variant='light-border' style={{ marginTop: '0.7rem', padding: '0.1rem', lineHeight: 1}} onClick={()=>{console.log('clicked')}}><span style={{fontSize: '0.5rem', padding: '0.5rem', margin: 0}}>Book Now</span></StyledButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        );
    }
    /* end rendering functions */

    if (redirectToTrainers) {
        return <Redirect push to={{ pathname: "/trainers"}} />
    } else if (trainer) {// prevent rendering when event is undefined
        return (
            <Grid>
            <Content image={trainer.profilePictureLink}>
                <Box style={{minHeight: '10vh'}} justify='flex-start'>
                    <Grid justify='flex-start' className={classes.backButton}>
                        <button style={{display: 'inline-block'}} className={classes.chevronButton} onClick={() => setRedirectToTrainers(true)}>
                            <FaChevronLeft style={{margin: '2px 0 0 -1px'}} />
                        </button>
                        <Typography style={{display: 'inline-block'}}>Back to Camp Staff</Typography>
                    </Grid>
                </Box>
                <Grid container justify='center'>
                    <Grid container item xs={12}>
                        <Grid container item md={12}>
                            <Grid item xs={12} style={{margin: 'auto'}}>
                                <Typography style={{padding: '1rem'}} />
                            </Grid>
                            <Grid item xs={12} style={{margin: 'auto'}}>
                                <Typography variant='h2'>{trainer.first + ' ' + trainer.last}</Typography>
                            </Grid>
                            <Grid item container spacing={1} style={{margin: 'auto'}} justify='center' direction="row">
                                <Grid item>
                                    <Typography style={{display: 'inline'}} className={classes.skillPill}>{skillString}</Typography>
                                </Grid>
                                { trainer.club ?
                                    <Grid item>
                                        <Typography style={{display: 'inline'}} className={classes.skillPill}>{trainer.club}</Typography>
                                    </Grid>
                                    : ""
                                }
                            </Grid>
                        </Grid>
                        <Hidden mdDown><Grid item xs={3}/></Hidden>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={12} style={{margin: '3rem'}}>
                            <Typography variant='body1' align='center' dangerouslySetInnerHTML={{ __html: trainer.bioHtml }}></Typography>
                        </Grid>

                        {
                            trainer.events ? 
                            <Grid item xs={12}>
                                <Typography variant='h4'>Upcoming Availability</Typography>
                            </Grid>
                            :
                            ""
                        }
                        
                        <Grid container item xs={12} justify='center' style={{margin: 15}}>
                            {/* {event.events.map((event) => {return ( */}
                            {
                                trainer.events ?
                                trainer.events.map((event) => {return (
                                    <Grid item xs={6}>
                                        <Event style={{padding: '0.2rem'}} event={event}/>
                                    </Grid>
                                );})   
                                :
                                ""       
                            }                                                       
                        </Grid>
                    </Grid>
                </Grid>             
            </Content>
                {
                    trainers ?
                    <Grid container direction="row" justify="space-around" alignItems="center">                      
                        <Carousel data={trainers} carouselTitle="Camp Staff" slideType="trainer"/>
                    </Grid> 
                    : ""
                }                
            </Grid>
            
        )
    }
    else return null
}

export default Trainer;
