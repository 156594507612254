import React from 'react';
import { useContext } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Grid, Hidden } from '@material-ui/core';
import Image from 'material-ui-image';
import pmp_image_url from '../assets/pmp_banner.jpeg';
import sos_image_url from '../assets/sos_signup.jpeg';
import AppContext from '../context';


function Content({...rest}) {
    const theme = useTheme();
    const context = useContext(AppContext);
    const domain = context.domain;
    
    /*
     All children passed into the left of the two slots. 
     Image goes in right slot if defined, else a default image does.

     On sm-, the image dissapears completely. 
     On xl, the image gets shrunk a bit as to not be absurdly enourmous.
    */ 
	return (
        <Grid style={{position: 'relative', padding: '2.5%'}} container direction="row" justify="space-between" alignItems="center">
            <Grid item xl={7} md={6} xs={11} container alignItems="center" justify="center">
                <Grid item>
                    {rest.children}
                </Grid>
            </Grid>
            <Hidden smDown>
                {domain.host === 'sos' ?
                    <Grid item sm={5} xl={4}>
                        <Image aspectRatio={0.8} src={rest.image || sos_image_url} alt="generic sports image" cover color={theme.palette.primary.main}/>
                    </Grid>
                    :
                    <Grid item sm={5} xl={4}>
                        <Image aspectRatio={0.8} src={rest.image || pmp_image_url} alt="generic sports image" cover color={theme.palette.primary.main}/>
                    </Grid>
                }
                
            </Hidden>
        </Grid>
    );
}

export default Content;