import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Link } from 'react-router-dom';
import { Grid, Divider, Typography, Hidden, Box } from '@material-ui/core';

import logo from '../assets/pmpLogo.png';
import laxlogo from '../assets/sos-logo.png';
import AuthPair from './AuthPair';

function AppHeader(props) {
    const { domain } = props;
    const theme = useTheme();
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    const logoAlign = isSmDown ? "center" : "flex-start";
    const titleAlign = isSmDown ? "center" : "left";
    const authJustify = isSmDown ? "center" : "flex-end";
    /* 
    Grid that changes shape from |3 6 3| for sm- to |6 6 \n 12| for md+
    */
    function HeaderGrid({logoName, links, authPair}) {
        return <Grid container direction="row" spacing={2}>
            <Hidden mdUp>
                <Grid item xs={6} container>{logoName}</Grid>
                <Grid item xs={6} container>{authPair}</Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12} container>{links}</Grid>
            </Hidden>
            <Hidden smDown>
                <Grid item xs={3} container>{logoName}</Grid>
                <Grid item xs={6} container>{links}</Grid>
                <Grid item xs={3} container>{authPair}</Grid>
            </Hidden>
        </Grid>
    }

    function HeaderLink({xs=2, to, variant="h5", ...props}) {
        return (
            <Grid item xs={xs}>
                <Link to={to} style={{textDecoration: 'none'}}><Typography variant={variant} {...props}>{props.children}</Typography></Link> 
            </Grid>
        );
    }    

    const redirectToEvent = () => {
        window.location.href='/event/sos-lacrosse-camp-6';
    }

    return (
        <Box> 
            <Box py={2} px={2}>
                <Grid container direction="row" justify="space-between" alignItems="center">
                    <HeaderGrid
                        logoName={
                            <Grid container direction="column" alignItems={logoAlign} justify="center">
                                <Grid item><Link to="/"><img src={domain.logo} alt="logo" style={{height: '120px'}}/></Link></Grid>
                                {/* xs=0 so force the typography onto fewer lines */}
                                {/* <HeaderLink xs={0} to="/" variant="h4" align={titleAlign}>{domain.companyName}</HeaderLink> */}
                            </Grid>
                        }
                        links={
                            <Grid item xs={12} container justify="center" alignItems="center" direction="row">
                                <HeaderLink to="/">Home</HeaderLink>
                                {/* <HeaderLink to="/camp-mission">Camp Mission</HeaderLink> */}
                                <Grid item xs={2}>
                                    <Link style={{textDecoration: 'none'}} onClick={ () => redirectToEvent()  }><Typography variant={"h5"} {...props}>Camp Info</Typography></Link> 
                                </Grid>
                                <HeaderLink to="/trainers">Camp Staff</HeaderLink>
                            </Grid>
                        }
                        authPair={
                            <Grid container direction="row" justify={authJustify} alignItems="center">
                                <AuthPair xs={12} md={12} justify={authJustify}></AuthPair>
                            </Grid>
                        }
                    ></HeaderGrid>
                </Grid>
            </Box>
            <Divider variant="fullWidth"></Divider>
        </Box>
    );
}

export default AppHeader;