import React from 'react';

import { useTheme } from "@material-ui/core/styles";

/* 
Wrapper around any component that allows for accessing MORE theme colors 
Has 2 serious arguments: the component to be cloned and the name of the color property (as measured by CSS) to edit.
For example, to create a button with a background color of theme.palette.primary.dark:
<PaletteColorizer component={<Button></Button>} colorProperty="backgroundColor" backgroundColor="primary.dark" >ButtonText</PaletteColorizer>
*/
function PaletteColorizer({component, colorProperty, ...props}) {
	const theme = useTheme();
	// The mui syntax calls for palette colors to be accessed with "colorclass.brightness", like "primary.dark"
	const selectors = props?.[colorProperty]?.split(".") ?? [];
	/*
	Do a bunch of null checking so the color set by style.colorProperty will be
	1) The theme color specified IF anything is properly specified
	2) The incoming value of style.color IF style.colorProperty is specified
	3) Nothing (empty string, so black) IF neither of the above
	*/
	const newColor = theme.palette?.[selectors[0]]?.[selectors[1]] ?? (props?.style?.[colorProperty] ?? "");
	// Only want to forward a subset of props to the childmost element
	const forwardProps = {...props};
	delete forwardProps[colorProperty];
	// Concat this new color onto the incoming style
	const style = {...props.style};
	style[colorProperty] = newColor;
	// Clone component element with fancy new style prop appended
	return React.cloneElement(component, {...forwardProps, style}, props.children);
}

export default PaletteColorizer;