import React from 'react';
import { Grid, makeStyles, Modal } from '@material-ui/core';
import close from '../assets/close.svg';

const useStyles = makeStyles( theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    minWidth: '60vw',
    maxWidth:'1000px',
    margin: 'auto',
    marginTop:'10vh',    
    minHeight: '400px',
    maxHeight:'80%',
    border: `solid 1.5px ${theme.palette.secondary.main}`,
    borderRadius: '10px',
    alignItems: 'center',
    outline: 'none',
    justifyContent: 'space-evenly',
    alignContent: 'stretch',
    position: 'relative'
  },
  close: {
    position: 'absolute',
    top: '0.3rem',
    right: '0.3rem',
    cursor: 'pointer',
  }
}));

function StyledModal(props) {
    const classes = useStyles();

    return (
        <Modal open={props.open} onClose={props.onClose}>
          <Grid container className={classes.root} {...props}>
            <div className={classes.close} onClick={props.onClose}>
              <img height="50px" src={close} />
            </div>
            {props.children}
          </Grid>
        </Modal>
    );
}

export default StyledModal